import Button from 'components/button';
import Input from 'components/input';
import Loading from 'components/loading';
import { useFormik } from 'formik';
import GoBackButton from 'pages/authenticatePage/authenticate/goBackButton';
import ResetPasswordSuccessPopup from 'pages/authenticatePage/authenticate/resetPasswordPage/ResetPasswordSuccessPopup';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetPassword } from 'services/authenticate';
import * as Yup from 'yup';

const ResetPasswordForm = ({ token }) => {
  const initialValues = {
    password: '',
    confirmPassword: ''
  };
  const navigate = useNavigate();
  const resetPasswordService = useResetPassword();
  const [isResetting, setIsResetting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      password: Yup.string().min(8, 'Password must have at least 8 character').trim().required('Password can not be null'),
      confirmPassword: Yup.string()
        .required('Confirm password can not be null')
        .oneOf([Yup.ref('password'), null], 'Confirm password does not match')
    }),
    onSubmit: async (values) => {
      setIsResetting(true);
      try {
        const params = {
          token: token,
          password: values.password,
          confirmPassword: values.confirmPassword
        };
        const resetPasswordResponse = await resetPasswordService(params);

        if (resetPasswordResponse) setIsOpenModal(true);
      } catch (error) {
        toast.error(error);
      } finally {
        setIsResetting(false);
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[508px] mx-auto form-group'>
        <div className='form-item'>
          <label htmlFor='password'>Passwort</label>
          <Input type='password' id='password' name='password' value={formik.values.password} onChange={formik.handleChange} />
          {formik.touched.password && formik.errors.password ? (
            <span className='form-error'>{formik.errors.password}</span>
          ) : (
            <span className='block mt-2 text-sm text-outer-space sm:mt-4'>Das Passwort muss mind. 8 Zeichen haben.</span>
          )}
        </div>

        <div className='form-item'>
          <label htmlFor='confirmPassword'>Passwort wiederholen</label>
          <Input type='password' id='confirmPassword' name='confirmPassword' value={formik.values.confirmPassword} onChange={formik.handleChange} />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <span className='form-error'>{formik.errors.confirmPassword}</span>
          ) : (
            <></>
          )}
        </div>

        <div className='w-full flex justify-center items-center space-y-4 !mt-11 flex-col'>
          <Button type='submit' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit'>
            Reset password
          </Button>
          <GoBackButton label='Back to log in' onClick={() => navigate('..', { relative: true })} />
        </div>
      </form>
      {isResetting ? <Loading isLoadingMask /> : <></>}
      <ResetPasswordSuccessPopup isOpen={isOpenModal} />
    </>
  );
};

ResetPasswordForm.propTypes = {
  token: string
};

export default ResetPasswordForm;
