import { string } from 'prop-types';
import React from 'react';

const GoBackButton = ({ label, ...props }) => {
  return (
    <button
      {...props}
      type='button'
      className='outline-none border-none text-center text-outer-space flex justify-center items-center space-x-2 transition-ease-in-out focus:ring-0 hover:text-primary'
    >
      <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12.6667 6.83333H1M1 6.83333L6.83333 12.6667M1 6.83333L6.83333 1'
          stroke='currentColor'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      <span>{label}</span>
    </button>
  );
};

GoBackButton.propTypes = {
  label: string
};

export default GoBackButton;
