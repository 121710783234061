import { LocalStorageName } from 'constants/index';
import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import { generateUserDataObject } from 'services/authenticate/utils';
import fetcher from 'services/utils/fetcher';
import useSWR, { useSWRConfig } from 'swr';

const getAuthUrl = `${AUTHENTICATE_BASE_URL}/get-auth`;

export const useMutateGetAuthCase = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getAuthUrl);
  }, [mutate]);
};

const useGetAuth = () => {
  const { mutate, isValidating, data, error } = useSWR(getAuthUrl, fetcher, {
    revalidateIfStale: false
  });
  let accountInfo = null;
  if (data) {
    accountInfo = generateUserDataObject(data);

    window.localStorage.setItem(LocalStorageName.ACCOUNT_INFO_NAME, JSON.stringify(accountInfo));
  }

  return {
    errorMessage: error,
    isLoading: isValidating,
    accountInfo: accountInfo,
    mutate
  };
};

export default useGetAuth;
