import { string } from 'prop-types';
import React from 'react';

const AuthWithGoogleButton = ({ label, ...props }) => {
  return (
    <button
      {...props}
      type='button'
      className='w-full max-w-xs outline-none border border-gray-300 rounded-lg p-2.5 flex justify-center items-center space-x-3 focus:ring-0 hover:bg-gray-300/30'
    >
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_199_308)'>
          <path
            d='M24.2663 12.7765C24.2663 11.9608 24.2001 11.1406 24.059 10.3381H12.7402V14.9591H19.222C18.953 16.4495 18.0888 17.7679 16.8233 18.6056V21.604H20.6903C22.9611 19.514 24.2663 16.4274 24.2663 12.7765Z'
            fill='#4285F4'
          />
          <path
            d='M12.7401 24.5008C15.9766 24.5008 18.7059 23.4382 20.6945 21.6039L16.8276 18.6055C15.7517 19.3375 14.3627 19.752 12.7445 19.752C9.61388 19.752 6.95946 17.6399 6.00705 14.8003H2.0166V17.8912C4.05371 21.9434 8.2029 24.5008 12.7401 24.5008Z'
            fill='#34A853'
          />
          <path
            d='M6.00277 14.8002C5.50011 13.3099 5.50011 11.696 6.00277 10.2057V7.11475H2.01674C0.314734 10.5055 0.314734 14.5004 2.01674 17.8912L6.00277 14.8002Z'
            fill='#FBBC04'
          />
          <path
            d='M12.7401 5.24966C14.4509 5.2232 16.1044 5.86697 17.3434 7.04867L20.7695 3.62262C18.6001 1.5855 15.7208 0.465534 12.7401 0.500809C8.2029 0.500809 4.05371 3.05822 2.0166 7.11481L6.00264 10.2058C6.95064 7.36173 9.60947 5.24966 12.7401 5.24966Z'
            fill='#EA4335'
          />
        </g>
        <defs>
          <clipPath id='clip0_199_308'>
            <rect width='24' height='24' fill='white' transform='translate(0.5 0.5)' />
          </clipPath>
        </defs>
      </svg>
      <span>{label}</span>
    </button>
  );
};

AuthWithGoogleButton.propTypes = {
  label: string
};

export default AuthWithGoogleButton;
