export default class AccountStatus {
  static STATUS_ACTIVE = true;
  static STATUS_NOT_ACTIVE = false;
  static IS_VERIFIED_EMAIL = true;
  static IS_NOT_VERIFIED_EMAIL = false;
  static IS_VERIFIED_BY_ADMIN = true;
  static IS_NOT_VERIFIED_BY_ADMIN = false;
  static USER_ROLE = 1;
  static ADMIN_ROLE = 0;
}
