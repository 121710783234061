import { arrayOf, element, oneOfType, string } from 'prop-types';
import React, { forwardRef } from 'react';

const Container = forwardRef(({ children, className = '' }, ref) => {
  return (
    <div ref={ref} className={`mx-auto px-4 py-3.5 sm:py-7 xl:container ${className}`}>
      {children}
    </div>
  );
});

Container.displayName = 'Container';

Container.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  className: string
};

export default Container;
