import SpinnerComp from 'components/spinnerComp';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { generateGGDriveLink } from 'utils/generateGGDriveLink';

const PLACEHOLDER_IMAGE = 'https://placehold.co/400?text=Load+image+failed';

const Image = ({ fileId, loadingClassName = '', ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [placeholderImageSrc, setPlaceholderImageSrc] = useState(null);

  return (
    <>
      <img
        {...props}
        src={placeholderImageSrc ?? generateGGDriveLink(fileId)}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);

          setPlaceholderImageSrc(PLACEHOLDER_IMAGE);
        }}
      />
      {isLoading ? (
        <div className={`w-full h-full bg-black/80 flex justify-center items-center ${loadingClassName}`}>
          <SpinnerComp />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

Image.propTypes = {
  fileId: string,
  loadingClassName: string
};

export default Image;
