import { useIsInViewport } from 'hooks/index';
import { bool, number } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const CountUpNumber = ({ startNumber = 0, endNumber, duration = 100, step = 1, decimal = false }) => {
  const numberRef = useRef(null);
  const isNumberRefInViewport = useIsInViewport(numberRef);
  const [startCounter, setStartCounter] = useState(false);
  const [countNumber, setCountNumber] = useState(startNumber);

  useEffect(() => {
    if (isNumberRefInViewport) setStartCounter(true);
    else setStartCounter(false);
  }, [isNumberRefInViewport]);

  useEffect(() => {
    if (startCounter) countNumberInterval();
  }, [startCounter]);

  const countNumberInterval = () => {
    let numb = startNumber;

    const counter = setInterval(() => {
      numb += step;
      if (numb >= endNumber) {
        setCountNumber(endNumber);

        clearInterval(counter);
      } else {
        setCountNumber(numb);
      }
    }, duration);
  };

  return <span ref={numberRef}>{decimal ? countNumber.toLocaleString('vi-VN') : countNumber}</span>;
};

CountUpNumber.propTypes = {
  startNumber: number,
  endNumber: number,
  duration: number,
  step: number,
  decimal: bool
};

export default CountUpNumber;
