import AdminLogo from 'pages/admin/components/adminHeader/AdminLogo';
import { adminHeaderLinks } from 'pages/admin/components/adminHeader/configs';
import React from 'react';
import { NavLink } from 'react-router-dom';

const AdminHeader = () => {
  return (
    <header className='w-full relative z-10 bg-linear-primary-color'>
      <div className='mx-auto py-3.5 flex items-center justify-center px-4 sm:py-7 2xl:container'>
        <AdminLogo />
        <div className='relative text-white uppercase z-10 flex-1 flex justify-end items-center space-x-7 xl:text-lg xl:space-x-20'>
          {adminHeaderLinks.map((menu) => (
            <NavLink key={menu.path} to={menu.path} className='nav-link' end>
              {menu.label}
            </NavLink>
          ))}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
