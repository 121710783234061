import { useParams } from 'react-router-dom';
import { REGISTER_MEMBER_ENDPOINT } from 'services/registerMember/configs';
import fetcher from 'services/utils/fetcher';
import useSWR from 'swr';

const useRegisterMemberForm = () => {
  const { formId } = useParams();
  const { isValidating, mutate, data, error } = useSWR(`${REGISTER_MEMBER_ENDPOINT}/get-form-by-id/${formId}`, fetcher);

  return {
    data: data
      ? {
          email: data.email,
          date: data.date
        }
      : null,
    isLoading: isValidating,
    mutate,
    errorMessage: error
  };
};

export default useRegisterMemberForm;
