import Button from 'components/button';
import Input from 'components/input';
import Loading from 'components/loading';
import { useFormik } from 'formik';
import GoBackButton from 'pages/authenticatePage/authenticate/goBackButton';
import SentTokenSuccessPopup from 'pages/authenticatePage/authenticate/resetPasswordPage/SentTokenSuccessPopup';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetResetPasswordToken } from 'services/authenticate';
import * as Yup from 'yup';

const GetResetPasswordTokenForm = () => {
  const initialValues = {
    email: ''
  };
  const navigate = useNavigate();
  const getResetPasswordTokenService = useGetResetPasswordToken();
  const [isGetting, setIsGetting] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter a valid email').trim().required('Please enter your email')
    }),
    onSubmit: async (values) => {
      setIsGetting(true);
      try {
        const response = await getResetPasswordTokenService(values.email);

        if (response) {
          setIsOpenPopup(true);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsGetting(false);
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[508px] mx-auto form-group'>
        <div className='form-item'>
          <label htmlFor='email'>Deine E-Mail-Adresse</label>
          <Input type='email' id='email' name='email' value={formik.values.email} onChange={formik.handleChange} />
          {formik.touched.email && formik.errors.email ? <span className='form-error'>{formik.errors.email}</span> : <></>}
        </div>
        <div className='w-full flex justify-center items-center space-y-4 !mt-11 flex-col'>
          <Button type='submit' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit' disabled={isGetting}>
            Reset password
          </Button>
          <GoBackButton label='Back to log in' onClick={() => navigate('..', { relative: true })} />
        </div>
      </form>
      {isGetting ? <Loading isLoadingMask /> : <></>}
      <SentTokenSuccessPopup isOpen={isOpenPopup} />
    </>
  );
};

export default GetResetPasswordTokenForm;
