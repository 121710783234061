import AdminHeader from 'pages/admin/components/adminHeader';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AdminAccountPage = () => {
  return (
    <div className='min-h-screen w-full flex flex-col overflow-hidden'>
      <AdminHeader />
      <Outlet />
    </div>
  );
};

export default AdminAccountPage;
