import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AuthenticatePageLayout = () => {
  return (
    <div className='min-h-screen w-full flex flex-col overflow-hidden'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default AuthenticatePageLayout;
