import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SwiperComp.scss';

import LeftArrow from 'assets/images/left-arrow-polygon.png';
import RightArrow from 'assets/images/right-arrow-polygon.png';
import { arrayOf, element, oneOfType } from 'prop-types';
import { forwardRef, useRef } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

const SwiperComp = forwardRef(({ children, ...props }, ref) => {
  const navPrevButton = useRef(null);
  const navNextButton = useRef(null);

  const onBeforeInit = (Swiper) => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation;

      navigation.prevEl = navPrevButton.current;

      navigation.nextEl = navNextButton.current;
    }
  };

  return (
    <div ref={ref} className='w-full relative scale-100 swiper-comp'>
      <button ref={navPrevButton} className='swiper-comp__btn swiper-comp__btn-prev'>
        <img src={LeftArrow} alt='swiper-left-arrow' />
      </button>
      <button ref={navNextButton} className='swiper-comp__btn swiper-comp__btn-next'>
        <div className=''>
          <img src={RightArrow} alt='swiper-right-arrow' />
        </div>
      </button>
      <Swiper
        {...props}
        onBeforeInit={onBeforeInit}
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: '.swiper-comp__next',
          prevEl: '.swiper-comp__prev'
        }}
      >
        {children}
      </Swiper>
    </div>
  );
});

SwiperComp.displayName = 'SwiperComp';

SwiperComp.propTypes = {
  children: oneOfType([element.isRequired, arrayOf(element.isRequired)])
};

export default SwiperComp;
