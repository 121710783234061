import { SvgIcons } from 'assets/images/SvgIcons';
import Image from 'components/image';
import { arrayOf, exact, number, oneOf, shape, string } from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { generateUuid } from 'utils/generateUuid';

const ImageTextItem = ({ data, itemIndex, totalRecord }) => {
  const svgStrokeWidth = 10;
  const containerRef = useRef(null);
  const lineContainerRef = useRef(null);
  const [verticalLineHeight, setVerticalLineHeight] = useState(0);
  const [horizontalLineWidth, setHorizontalLineWidth] = useState(0);

  useLayoutEffect(() => {
    if (containerRef?.current && lineContainerRef?.current) {
      const containerCurrent = containerRef.current;
      const lineContainerCurrent = lineContainerRef.current;

      const containerHeight = containerCurrent.offsetHeight;
      const containerWidth = containerCurrent.offsetWidth;

      if ((itemIndex + 1) % 2) {
        lineContainerCurrent.style.left = 0;
      } else {
        lineContainerCurrent.style.right = 0;
      }

      const lineContainerTop = lineContainerCurrent.getBoundingClientRect().top;
      let lineContainerScrollPercent = lineContainerTop / document.documentElement.scrollHeight;
      if (itemIndex === 0) lineContainerScrollPercent -= 0.06;
      else lineContainerScrollPercent -= 0.03;

      let verticalLineHeight = 0;
      let horizontalLineWidth = svgStrokeWidth;

      window.addEventListener('scroll', () => {
        // INFO: what % down is it?
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);

        // INFO: length to offset the dashes
        const drawLength = (scrollPercentage - lineContainerScrollPercent) * 110;

        if (scrollPercentage >= lineContainerScrollPercent) {
          if (verticalLineHeight < containerHeight) {
            verticalLineHeight += drawLength + 8;
          } else {
            verticalLineHeight = containerHeight;
          }

          if (verticalLineHeight >= containerHeight && itemIndex !== totalRecord - 1) {
            if (horizontalLineWidth < containerWidth) {
              horizontalLineWidth += drawLength + 5;
            } else {
              horizontalLineWidth = containerWidth;
            }
          }
        }

        setVerticalLineHeight(verticalLineHeight);

        setHorizontalLineWidth(horizontalLineWidth);
      });
    }
  }, [containerRef, lineContainerRef, itemIndex, totalRecord]);

  return (
    <div ref={containerRef} className='image-text-listing-diagram__item'>
      <div ref={lineContainerRef} className='line-container'>
        <svg
          width={horizontalLineWidth}
          height={verticalLineHeight}
          viewBox={`0 0 ${horizontalLineWidth} ${verticalLineHeight}`}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='xMidYMax meet'
        >
          <path d={`M5 0V${verticalLineHeight}H${horizontalLineWidth}`} stroke='url(#paint0_linear_201_4)' strokeWidth={svgStrokeWidth} />
          <defs>
            <linearGradient id='paint0_linear_201_4' x1='-4395' y1='141.598' x2='-3644.99' y2='-2315.55' gradientUnits='userSpaceOnUse'>
              <stop stopColor='#988313' stopOpacity='0' />
              <stop offset='0.510417' stopColor='#988313' />
              <stop offset='1' stopColor='#988313' stopOpacity='0' />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='item-image'>
        <div className='item-image__wrapper'>
          <Image fileId={data.fileId} alt={data.name} className='image' loadingClassName='image' />
        </div>
      </div>
      <div className='item-body'>
        <h2 className='name'>{data.name}</h2>
        <h6 className='position'>{data.position}</h6>
        {data.description ? <p className='description'>{data.description}</p> : <></>}
        <div className='social-icon'>
          {data.social.map((social) => (
            <div key={generateUuid()} className='social-icon__container'>
              <div className='social-icon__wrapper'>
                <a href={social.link} target='_blank' className='social-icon__link' rel='noreferrer'>
                  {React.cloneElement(social.type === 'instagram' ? SvgIcons.Instagram : SvgIcons.Youtube)}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ImageTextItem.propTypes = {
  data: shape({
    fileId: string,
    name: string,
    position: string,
    description: string,
    social: arrayOf(
      exact({
        type: oneOf(['instagram', 'youtube']),
        link: string
      })
    )
  }),
  itemIndex: number,
  totalRecord: number
};

export default ImageTextItem;
