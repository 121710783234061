import Container from 'components/container';
import { string } from 'prop-types';
import React from 'react';

const PageContainer = ({ pageClassName = '', ...props }) => {
  return <Container {...props} className={`w-full h-full flex flex-col justify-center items-center space-y-16 lg:space-y-32 ${pageClassName}`} />;
};

PageContainer.propTypes = {
  pageClassName: string
};

export default PageContainer;
