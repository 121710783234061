import Container from 'components/container';
import { footerLinks } from 'components/footer/configs';
import Logo from 'components/logo';
import SocialButtons from 'components/socialButtons';
import { string } from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = ({ className = '' }) => {
  const { pathname } = useLocation();

  return (
    <footer className={`w-full bg-linear-primary-color-revert relative mt-auto ${className}`}>
      <Container className='flex items-center flex-col justify-center space-y-8 lg:space-y-0 lg:flex-row lg:justify-between'>
        <Logo />
        <div className='flex justify-evenly items-center text-white flex-col order-3 space-y-5 lg:space-x-10 lg:space-y-0 lg:flex-row lg:order-2'>
          {footerLinks.map((link) => (
            <NavLink key={link.link} to={link.link} state={{ fromPath: pathname }} rel='noreferrer' className='block nav-link'>
              {link.label}
            </NavLink>
          ))}
        </div>
        <SocialButtons type='horizontal' className='order-2 lg:order-3' />
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  className: string
};

export default Footer;
