import EmailVerificationPopup from 'pages/authenticatePage/registerPage/EmailVerificationPopup';
import RegisterFormPopup from 'pages/authenticatePage/registerPage/registerFormPopup';
import React, { useState } from 'react';

const RegisterButton = () => {
  const [isOpenRegisterForm, setIsOpenRegisterForm] = useState(true);

  return (
    <>
      <RegisterFormPopup isOpenRegisterForm={isOpenRegisterForm} setIsOpenRegisterForm={setIsOpenRegisterForm} />
      <EmailVerificationPopup isOpen={!isOpenRegisterForm} />
    </>
  );
};

export default RegisterButton;
