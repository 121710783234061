import LogoIcon from 'assets/images/logo-white.svg';
import { RouteBasePath } from 'constants/index';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();

  const homePagePath = useMemo(() => {
    return RouteBasePath.HomePage;
  }, []);

  return (
    <div className='text-white flex justify-start items-center cursor-pointer transition-ease-in-out z-40 '>
      <div className='w-14 xl:w-20' onClick={() => navigate(homePagePath)}>
        <div className='w-full pb-100 relative'>
          <img src={LogoIcon} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
        </div>
      </div>
      <Link to={homePagePath} className='uppercase text-2xl whitespace-nowrap sm:text-3xl'>
        the kingdom
      </Link>
    </div>
  );
};

export default Logo;
