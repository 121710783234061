import CookieConsentComp from 'components/cookieConsentComp';
import MainLayout from 'components/mainLayout';
import ToastifyProvider from 'components/toastifyProvider';
import { AuthProvider } from 'contexts/index';
import React from 'react';
import fetcher from 'services/utils/fetcher';
import { SWRConfig } from 'swr';

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        fetcher
      }}
    >
      <AuthProvider>
        <MainLayout />
        <CookieConsentComp />
        <ToastifyProvider />
      </AuthProvider>
    </SWRConfig>
  );
}

export default App;
