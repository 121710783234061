import { socialDetails } from 'components/socialButtons/configs';
import { oneOf, string } from 'prop-types';
import React, { useMemo } from 'react';

const SocialButtons = ({ className = '', type = 'horizontal' }) => {
  const socialButtonTypeClassName = useMemo(() => {
    return type === 'horizontal' ? 'flex-row space-x-6' : 'flex-col space-y-6';
  }, [type]);

  return (
    <div className={`flex justify-center items-center w-fit ${socialButtonTypeClassName} ${className}`}>
      {socialDetails.map((social) => (
        <a
          key={social.title}
          title={social.title}
          href={social.url}
          target='_blank'
          className='w-12 h-12 block text-white transition-ease-in-out hover:text-hover-link'
          rel='noreferrer'
        >
          {React.cloneElement(social.icon, { className: 'w-full h-full' })}
        </a>
      ))}
    </div>
  );
};

SocialButtons.propTypes = {
  className: string,
  type: oneOf(['horizontal', 'vertical'])
};

export default SocialButtons;
