import './Header.scss';

import HomepageHeader from 'components/header/hompageHeader';
import Logo from 'components/logo';
import { BreakPoints } from 'constants/index';
import { useWindowSize } from 'hooks/index';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setHeaderHeight } from 'stores/layout/layoutSlice';

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const { width } = useWindowSize();
  const [scrollY, setScrollY] = useState(0);

  const isDisplayHamburgerButton = useMemo(() => {
    return width <= BreakPoints.BreakpointLg;
  }, [width]);

  useLayoutEffect(() => {
    const handleSetHeaderHeight = () => {
      if (headerRef?.current) {
        const headerHeight = headerRef.current.offsetHeight;

        dispatch(setHeaderHeight(headerHeight));
      }
    };

    document.addEventListener('resize', handleSetHeaderHeight);

    handleSetHeaderHeight();

    return () => document.removeEventListener('resize', handleSetHeaderHeight);
  }, [headerRef?.current]);

  useLayoutEffect(() => {
    const getWindowScrollY = () => {
      setScrollY(window.scrollY);
    };

    document.addEventListener('scroll', getWindowScrollY);
  }, []);

  return (
    <header
      ref={headerRef}
      className={`${scrollY >= headerRef?.current?.offsetHeight ? 'active-sticky' : ''} w-full top-0 bg-linear-primary-color z-50 header-comp`}
    >
      <div
        className={`mx-auto py-3.5 flex items-center ${isDisplayHamburgerButton ? 'justify-between' : 'justify-center'} px-4 sm:py-7 2xl:container`}
      >
        <Logo />
        {/* {pathname.includes(RouteBasePaths.LoggedProfilePage) ? (*/}
        {/*  <LoggedHeader headerOffsetHeight={headerRef.current?.offsetHeight ?? 0} isDisplayHamburgerButton={isDisplayHamburgerButton} />*/}
        {/* ) : (*/}
        <HomepageHeader headerOffsetHeight={headerRef.current?.offsetHeight ?? 0} isDisplayHamburgerButton={isDisplayHamburgerButton} />
        {/* )}*/}
      </div>
    </header>
  );
};

export default Header;
