import { LocalStorageName } from 'constants/index';
import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useGetVerifyEmail = () => {
  const userId = window.localStorage.getItem(LocalStorageName.USER_ID_NAME);

  return useCallback(async () => {
    return await axiosInstance.post(`${AUTHENTICATE_BASE_URL}/get-verify-email`, {
      userId: JSON.parse(userId)
    });
  }, [userId]);
};

export default useGetVerifyEmail;
