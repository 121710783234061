import Container from 'components/container';
import Image from 'components/image';
import SwiperComp from 'components/swiperComp';
import Title from 'components/title';
import { any, arrayOf, string } from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { generateUuid } from 'utils/generateUuid';

const FoundedTraderSwiper = ({ dataName, data, setRef }) => {
  const swiperRef = useRef(null);

  useLayoutEffect(() => {
    if (setRef) setRef(swiperRef);
  }, [swiperRef]);

  return (
    <section className='w-full founded-trader__swiper'>
      <Container className='space-y-10 xl:space-y-20'>
        <Title subtitle={dataName} subtitleTag='h6' />
        <SwiperComp
          ref={swiperRef}
          slidesPerView={1}
          loop={data.length >= 5}
          navigation={true}
          spaceBetween={35}
          centeredSlides={false}
          breakpoints={{
            640: {
              slidesPerView: data.length === 1 ? 1 : 3,
              centeredSlides: true,
              spaceBetween: 25
            }
          }}
        >
          {data.map((image) => (
            <SwiperSlide key={generateUuid()} className={`founded-trader__swiper-item ${data.length <= 1 ? 'one-child' : ''}`}>
              <div className='founded-trader__swiper-item__container'>
                <div className='bg-black founded-trader__swiper-item__wrapper'>
                  <Image
                    fileId={image}
                    alt='The Kingdom - Founded Trader'
                    className='founded-trader__swiper-item__image'
                    loadingClassName='founded-trader__swiper-item__image'
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperComp>
      </Container>
    </section>
  );
};

FoundedTraderSwiper.displayName = 'FoundedTraderSwiper';

FoundedTraderSwiper.propTypes = {
  dataName: string,
  data: arrayOf(string),
  setRef: any
};

export default FoundedTraderSwiper;
