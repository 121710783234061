import Button from 'components/button';
import Footer from 'components/footer';
import Header from 'components/header';
import PageContainer from 'components/pageContainer';
import Title from 'components/title';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { layoutSelector } from 'stores/layout/selector';

const PageNotFound = () => {
  const { headerHeight } = useSelector(layoutSelector);
  const navigate = useNavigate();

  return (
    <div className='min-h-screen w-full flex flex-col overflow-hidden relative'>
      <Header />
      <div className='min-h-screen w-full flex flex-col relative profile-page' style={{ padding: `${headerHeight}px 0` }}>
        <PageContainer pageClassName='relative my-auto'>
          <div className='w-full m-auto flex flex-col justify-center items-center text-white space-y-2'>
            <p className='text-2xl text-center text-shadow-black-85 text-white-85'>404</p>
            <Title title='Page not found' titleTag='h1' />
            <Button onClick={() => navigate('..', { relative: true })}>Homepage</Button>
          </div>
        </PageContainer>
      </div>
      <Footer className='mt-0' />
    </div>
  );
};

export default PageNotFound;
