import { bool, string } from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';

const DividerAnimation = ({ className = '', isFullWidth = true }) => {
  const linePathRef = useRef(null);
  const containerRef = useRef(null);
  const [lineWidth, setLineWidth] = useState(0);

  useLayoutEffect(() => {
    const handleSetLineWidth = () => {
      if (isFullWidth) {
        setLineWidth(window.innerWidth);
      } else {
        setLineWidth(containerRef?.current?.offsetWidth);
      }
    };

    document.addEventListener('resize', handleSetLineWidth);

    handleSetLineWidth();

    return () => document.removeEventListener('resize', handleSetLineWidth);
  }, [isFullWidth, containerRef]);

  useLayoutEffect(() => {
    if (linePathRef?.current) {
      const linePathCurrent = linePathRef.current;

      linePathCurrent.style.strokeDasharray = lineWidth + ' ' + lineWidth;

      linePathCurrent.style.strokeDashoffset = lineWidth;
      const linePathTop = linePathCurrent.getBoundingClientRect().top;
      const linePathPercent = linePathTop / document.documentElement.scrollHeight;

      window.addEventListener('scroll', () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        if (scrollPercentage >= linePathPercent) {
          const drawLengthPath = lineWidth * (scrollPercentage - linePathPercent);
          const calculateLineOffset = lineWidth - drawLengthPath * 8;
          if (calculateLineOffset > 0) {
            linePathCurrent.style.strokeDashoffset = calculateLineOffset;
          } else {
            linePathCurrent.style.strokeDashoffset = 0;
          }
        }
      });
    }
  }, [linePathRef, lineWidth]);

  return (
    <div ref={containerRef} className={`absolute text-center overflow-hidden w-fit left-0 ${className}`}>
      <svg
        width={lineWidth}
        height='10'
        viewBox={`0 0 ${lineWidth} 10`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='inline-block'
        preserveAspectRatio='xMidYMax meet'
      >
        <path ref={linePathRef} d={`M0 5L${lineWidth} 5.00044`} stroke='url(#paint0_linear_41_3)' strokeWidth='10' />
        <defs>
          <linearGradient id='paint0_linear_41_3' x1='1415.98' y1='10.5001' x2='1415.97' y2='-0.499874' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#988313' stopOpacity='0' />
            <stop offset='0.510417' stopColor='#988313' />
            <stop offset='1' stopColor='#988313' stopOpacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

DividerAnimation.propTypes = {
  className: string,
  isFullWidth: bool
};

export default DividerAnimation;
