export default class RequireAccessPageId {
  // static TradingAdvancedStrategyPage = 'e7eecae6-eea6-4b72-a251-eef0e0bb13a7';
  // static InformationPage = 'd3168047-2121-4964-9e46-d4ad85c11010';
  static EcxPage = '6d88e172-390e-4f3f-b1ec-94c86aa139ac';
  static IboPage = '8074e284-a0a4-487b-b896-bd54f71d0f30';
  static LeaderPage = '47f11da2-4c62-4d0b-a5aa-269ee01b950f';
  // static SFXPage = '722c4849-0fd1-4476-9ec6-9e1ad72a0e0a';
  // static DCXPage = 'b91804fd-a289-4265-bcff-3f12bd5ea97b';
  // static DCXFeaturePage = '5ce563d6-4114-4b01-a0ec-fb48505db35b';
}
