import { RouteBasePath } from 'constants/index';

export const headerNavLink = [
  {
    label: 'home',
    path: RouteBasePath.HomePage
  },
  {
    label: 'leader',
    path: RouteBasePath.LeaderPage
  },
  {
    label: 'Funded Trader',
    path: RouteBasePath.FoundedLeaderPage
  }
];
