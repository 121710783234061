import KingdomAfterMovie from 'assets/videos/Kingdom Aftermovie V2.mp4';
import VideoPlayer from 'components/videoPlayer';
import CircleContent from 'pages/client/homePage/firstSectionGroup/CircleContent';
import VideoBanner from 'pages/client/homePage/firstSectionGroup/VideoBanner';
import React, { useLayoutEffect, useRef, useState } from 'react';

const FirstSectionGroup = () => {
  const firstSection = useRef(null);
  const line1 = useRef(null);
  const line1Path = useRef(null);
  const secondSection = useRef(null);

  const [buttonRef, setButtonRef] = useState(null);
  const [circleRef, setCircleRef] = useState(null);
  const [pathLength, setPathLength] = useState(0);

  useLayoutEffect(() => {
    const handleSetLine1Style = () => {
      if (buttonRef?.current && line1?.current && firstSection?.current && circleRef?.current && secondSection?.current) {
        const buttonCurrent = buttonRef.current;
        const line1Current = line1.current;
        const circleCurrent = circleRef.current;
        const secondSectionCurrent = secondSection.current;
        const secondSectionStyle = window.getComputedStyle(secondSectionCurrent);
        const firstSectionCurrent = firstSection.current;

        const firstSectionHeight = firstSectionCurrent.offsetHeight;

        const secondSectionPaddingTop = Number.parseInt(secondSectionStyle.paddingTop.replace('px', ''));
        const circleOffsetTop = circleCurrent.offsetTop;

        const buttonOffsetTop = buttonCurrent.offsetTop;
        const buttonHeight = buttonCurrent.offsetHeight;

        line1Current.style.top = `${buttonOffsetTop + buttonHeight}px`;

        setPathLength(firstSectionHeight - buttonOffsetTop - buttonHeight + (circleOffsetTop + secondSectionPaddingTop));
      }
    };

    window.addEventListener('resize', handleSetLine1Style);

    handleSetLine1Style();

    return () => window.removeEventListener('resize', handleSetLine1Style);
  }, [buttonRef, line1, firstSection, secondSection, circleRef]);

  useLayoutEffect(() => {
    if (line1Path?.current) {
      const pathCurrent = line1Path.current;
      const pathLength = pathCurrent?.getTotalLength();

      pathCurrent.style.strokeDasharray = pathLength + ' ' + pathLength;

      pathCurrent.style.strokeDashoffset = pathLength;
      const pathSvgTop = pathCurrent.getBoundingClientRect().top;
      const pathSvgPercent = pathSvgTop / document.documentElement.scrollHeight - 0.05;

      window.addEventListener('scroll', () => {
        // INFO: what % down is it?
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        // INFO: length to offset the dashes
        const drawLength = pathLength * scrollPercentage;
        const multipleNumber = 8;
        if (scrollPercentage >= pathSvgPercent) {
          // INFO:  draw in reverse
          pathCurrent.style.strokeDashoffset = pathLength - drawLength * multipleNumber;
        }
      });
    }
  }, [line1Path, pathLength]);

  return (
    <div className='relative'>
      <VideoBanner ref={firstSection} setButtonRef={setButtonRef} />

      <div ref={line1} className='line-container line-container__1'>
        <svg width='10' height={pathLength} viewBox={`0 0 10 ${pathLength}`} fill='none' preserveAspectRatio='xMidYMax meet'>
          <path ref={line1Path} d={`M5 0V${pathLength}`} stroke='url(#paint0_linear_2_14)' strokeWidth='10' />
          <defs>
            <linearGradient id='paint0_linear_2_14' x1='-0.5' y1='136.5' x2='10.4998' y2='136.453' gradientUnits='userSpaceOnUse'>
              <stop stopColor='#988313' stopOpacity='0' />
              <stop offset='0.510417' stopColor='#988313' />
              <stop offset='1' stopColor='#988313' stopOpacity='0' />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <CircleContent ref={secondSection} setCircleRef={setCircleRef} line1Height={pathLength} />

      <section className='w-full h-screen relative'>
        <div
          className='w-full h-full inset-0 absolute z-10'
          style={{ background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 24.48%, rgba(0, 0, 0, 0) 77.6%, #000000 100%)' }}
        ></div>
        <div className='absolute w-full h-full inset-0 opacity-50'>
          <VideoPlayer
            // src='https://drive.google.com/uc?export=view&id=1miYhkuhR1m_rePnRYGr7wBiiiHSsB2da'
            src={KingdomAfterMovie}
            muted='muted'
            autoPlay
            srcType='video/mp4'
            // youTubeLink='https://youtu.be/4V6aocuzt_c'
            // srcType={YOU_TUBE_VIDEO_TYPE}
            // playing={true}
            className='w-full h-full object-cover'
          />
        </div>
      </section>
    </div>
  );
};

export default FirstSectionGroup;
