import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Input from 'components/input';
import { CustomRegex } from 'constants/index';
import { useFormik } from 'formik';
import AuthWithGoogleButton from 'pages/authenticatePage/authenticate/authWithGoogleButton';
import { func } from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const AgreePolicyLabel = () => {
  return (
    <>
      Ich stimme hiermit den{' '}
      <a href='#' className='link-style'>
        Nutzungsbedingungen
      </a>{' '}
      von Kingdom zu.Wenn du mehr darüber erfahren möchtest, wie Kingdom personenbezogene Daten erhebt, verwendet, weitergibt und schützt, lies bitte
      die{' '}
      <a href='#' className='link-style'>
        Datenschutzrichtlinie
      </a>{' '}
      von Kingdom.
    </>
  );
};

const RegisterForm = ({ onSubmit }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    agreePolicy: false
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('First name can not be null'),
      lastName: Yup.string().trim().required('Last name can not be null'),
      email: Yup.string().email('Email invalid.').trim().required('Email can not be null'),
      password: Yup.string()
        .trim()
        .required('Password can not be null')
        .matches(
          CustomRegex.Password,
          'Password is characters with no space and must contain: 1 number (0-9), 1 uppercase letters, lowercase letters, 1 non-alpha numeric number'
        ),
      agreePolicy: Yup.boolean().oneOf([true], 'Must agree policy')
    }),
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[508px] mx-auto form-group'>
        <div className='w-full flex space-y-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
          <div className='form-item'>
            <label htmlFor='firstName'>Vorname</label>
            <Input type='text' id='firstName' name='firstName' value={formik.values.firstName} onChange={formik.handleChange} />
            {formik.touched.firstName && formik.errors.firstName ? <span className='form-error'>{formik.errors.firstName}</span> : <></>}
          </div>
          <div className='form-item'>
            <label htmlFor='lastName'>Nachname</label>
            <Input type='text' id='lastName' name='lastName' value={formik.values.lastName} onChange={formik.handleChange} />
            {formik.touched.lastName && formik.errors.lastName ? <span className='form-error'>{formik.errors.lastName}</span> : <></>}
          </div>
        </div>

        <div className='form-item'>
          <label htmlFor='email'>Deine E-Mail-Adresse</label>
          <Input type='email' id='email' name='email' value={formik.values.email} onChange={formik.handleChange} />
          {formik.touched.email && formik.errors.email ? <span className='form-error'>{formik.errors.email}</span> : <></>}
        </div>

        <div className='form-item'>
          <label htmlFor='password'>Passwort</label>
          <Input type='password' id='password' name='password' value={formik.values.password} onChange={formik.handleChange} />
          {formik.touched.password && formik.errors.password ? <span className='form-error'>{formik.errors.password}</span> : <></>}
        </div>

        <div className='form-item'>
          <Checkbox
            label={<AgreePolicyLabel />}
            labelClassName='text-xs'
            checked={formik.values.agreePolicy}
            onChange={(event) => formik.setFieldValue('agreePolicy', event.target.checked)}
          />
          {formik.touched.agreePolicy && formik.errors.agreePolicy ? <span className='form-error'>{formik.errors.agreePolicy}</span> : <></>}
        </div>

        <div className='w-full flex justify-center items-center flex-col space-y-4 !mt-11 sm:space-y-0 sm:flex-row sm:space-x-9'>
          <Button type='submit' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit'>
            Registrieren
          </Button>
          <AuthWithGoogleButton label='Sign up with Google' />
        </div>
      </form>
    </>
  );
};

RegisterForm.propTypes = {
  onSubmit: func
};

export default RegisterForm;
