import Button from 'components/button';
import { LocalStorageName } from 'constants/index';
import RouteBasePaths from 'constants/RouteBasePaths';
import React from 'react';
import { useGetAuth } from 'services/authenticate';

const LoggedHeaderButtons = () => {
  const { mutate } = useGetAuth();

  const handleLogout = () => {
    window.localStorage.removeItem(LocalStorageName.ACCOUNT_INFO_NAME);

    window.localStorage.removeItem(LocalStorageName.ACCESS_TOKEN_NAME);

    mutate(null);
  };

  return (
    <div className='flex flex-col justify-center items-center space-y-4 sm:space-y-9 lg:space-y-0 lg:space-x-5 lg:flex-row'>
      <Button variant='secondary' onClick={() => (window.location.href = RouteBasePaths.WhatWaitForYouPage)}>
        Einarbeitung
      </Button>
      <Button variant='tertiary' onClick={handleLogout}>
        Log out
      </Button>
    </div>
  );
};

export default LoggedHeaderButtons;
