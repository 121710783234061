import Button from 'components/button';
import { func } from 'prop-types';
import React, { useState } from 'react';

export const leaderStatus = ['All', 'Chairman', 'P5000', 'P2000', 'P1000'];

const FilterByStatus = ({ onClick }) => {
  const [statusSelected, setStatusSelected] = useState(leaderStatus[0]);

  const handleClickFilter = (status) => {
    setStatusSelected(status);

    onClick(status);
  };

  return (
    <div className='w-full flex justify-center items-center flex-wrap gap-7 xl:gap-14'>
      {leaderStatus.map((status) => (
        <Button key={status} variant='secondary' active={statusSelected === status} onClick={() => handleClickFilter(status)}>
          {status}
        </Button>
      ))}
    </div>
  );
};

FilterByStatus.propTypes = {
  onClick: func
};

export default FilterByStatus;
