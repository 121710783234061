export default class RouteBasePaths {
  // INFO: client page paths
  static HomePage = '/';
  static LeaderPage = '/leader';
  static FoundedLeaderPage = '/founded-leader';
  static ImpressumPage = '/impressum';
  static DataProtectionPage = '/datenschutz';
  static VerifyEmailPage = '/verify-email';
  static BecomeAMember = '/mitglied-werden';

  static AuthenticatePage = '/authenticate';
  static RegisterPage = 'register';
  static ResetPasswordPage = 'reset-password';

  // INFO: logged client page paths
  static LoggedProfilePage = '/profile';
  static LoggedInfosPage = `${RouteBasePaths.LoggedProfilePage}/information`;
  static LoggedEcxPage = `${RouteBasePaths.LoggedProfilePage}/ecx`;

  static LoggedTradingPage = `${RouteBasePaths.LoggedProfilePage}/trading`;
  static LoggedTradingStepTwoPage = 'step-two';
  static LoggedTradingStepThreePage = 'step-three';
  static LoggedTradingStepFourPage = 'step-four';
  static LoggedTradingStepFivePage = 'step-five';
  static LoggedTradingAdvancedPage = 'advanced';
  static LoggedTradingBootcampPage = 'bootcamp';

  static LoggedIboPage = `${RouteBasePaths.LoggedProfilePage}/ibo`;
  static LoggedIboStepTwoPage = 'step-two';
  static LoggedIboLeaderPage = 'leader';

  static LoggedLeaderPage = `${RouteBasePaths.LoggedProfilePage}/leader`;
  static LoggedLeaderP600Page = 'p600';
  static LoggedLeaderP1000Page = 'p1000';
  static LoggedLeaderP2000Page = 'p2000';
  static LoggedLeaderP5000Page = 'p5000';

  static LoggedDCXPage = `${RouteBasePaths.LoggedProfilePage}/dcx`;
  static LoggedDCXKryptoPage = 'krypto';
  static LoggedDCXNft = 'nft';

  static LoggedDCXFeaturesPage = `${RouteBasePaths.LoggedProfilePage}/dcx-features`;

  static LoggedSFXPage = `${RouteBasePaths.LoggedProfilePage}/sfx`;

  static WhatWaitForYouPage = `${RouteBasePaths.LoggedProfilePage}/what-wait-for-you`;

  static BootcampPage = `${RouteBasePaths.LoggedProfilePage}/bootcamp`;
  static BootcampTrendtrapPage = 'trendtrap';

  // INFO: Admin page
  static AdminBasePage = '/admin';

  static AdminAccountPage = `${RouteBasePaths.AdminBasePage}/account`;
  static AdminAccountDetailPage = 'account-detail';

  static AdminRegisterMemberPage = `${RouteBasePaths.AdminBasePage}/register-member`;
  static AdminRegisterMemberFormDetailPage = 'account-detail';
}
