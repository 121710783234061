import ImageTextListingDiagram from 'components/imageTextListingDiagram';
import PageContainer from 'components/pageContainer';
import Title from 'components/title';
import { leaderData } from 'pages/client/leaderPage/configs';
import FilterByStatus, { leaderStatus } from 'pages/client/leaderPage/FilterByStatus';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'stores/layout/selector';

const LeaderPage = () => {
  // const PAGE_SIZE = 10;
  const { headerHeight } = useSelector(layoutSelector);
  // const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(leaderStatus[0]);

  // const [leaders, setLeaders] = useState({
  //   data: [],
  //   hasNextPage: false,
  //   currentPage: currentPage,
  //   totalItems: 0,
  //   totalPage: 0
  // });

  const filteredLeadersStatus = useMemo(() => {
    return filterStatus !== leaderStatus[0] ? leaderData.filter((leader) => leader.position.includes(filterStatus)) : leaderData;
  }, [leaderData, filterStatus]);

  // useEffectOnce(() => {
  //   const paginationData = pagination(leaderData, PAGE_SIZE, currentPage);
  //   if (paginationData.data) {
  //     setLeaders((prevState) => ({
  //       ...prevState,
  //       data: [...paginationData.data],
  //       currentPage: paginationData.currentPage,
  //       hasNextPage: paginationData.hasNextPage,
  //       totalItems: paginationData.totalItems,
  //       totalPage: paginationData.totalPage
  //     }));
  //   }
  // });

  // const handleLoadMore = useCallback(() => {
  //   if (leaders.hasNextPage) {
  //     const nextPage = currentPage + 1;
  //     const paginationData = pagination(leaderData, PAGE_SIZE, nextPage);
  //
  //     setCurrentPage(nextPage);
  //     if (!compareArray(paginationData.data, leaders.data)) {
  //       setLeaders((prevState) => ({
  //         ...prevState,
  //         data: [...prevState.data, ...paginationData.data],
  //         currentPage: paginationData.currentPage,
  //         hasNextPage: paginationData.hasNextPage,
  //         totalItems: paginationData.totalItems,
  //         totalPage: paginationData.totalPage
  //       }));
  //     }
  //   }
  // }, [leaders.hasNextPage, leaders.data]);

  const handleFilter = (status) => setFilterStatus(status);

  return (
    <div className='min-h-screen w-full flex flex-col relative' style={{ padding: `${headerHeight}px 0` }}>
      <PageContainer>
        <div className='w-full space-y-10 lg:space-y-20'>
          <Title title='leader' />
          <FilterByStatus onClick={handleFilter} />
          <Title subtitle='BEHIND EVERY GREAT LEADER WAS A MENTOR WHO HELPED THEM STAY IN THE GAME.' />
        </div>
        <div className='w-full'>
          {filteredLeadersStatus.length ? <ImageTextListingDiagram data={filteredLeadersStatus} /> : <></>}
          {/* {leaders.hasNextPage && filterStatus === leaderStatus[0] ? (*/}
          {/*  <div className='flex justify-center items-center mt-10'>*/}
          {/*    <Button variant='secondary' onClick={handleLoadMore}>*/}
          {/*      MEHR LADEN*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/* ) : (*/}
          {/*  <></>*/}
          {/* )}*/}
        </div>
      </PageContainer>
    </div>
  );
};

export default LeaderPage;
