import Button from 'components/button';
import DatePickerComp from 'components/datePickerComp';
import Input from 'components/input';
import Loading from 'components/loading';
import RouteBasePaths from 'constants/RouteBasePaths';
import { useFormik } from 'formik';
import { useAuth } from 'hooks/index';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRegisterMember } from 'services/registerMember';
import * as Yup from 'yup';

const BecomeAMemberForm = () => {
  const navigate = useNavigate();
  const { accountInfo } = useAuth();
  const registerMemberService = useRegisterMember();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: accountInfo?.email || '',
    date: null
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter an valid email').trim().required('Please enter your email'),
      date: Yup.string().trim().required('Please pick a date')
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const registerData = {
          email: values.email,
          date: values.date
        };
        const response = await registerMemberService(registerData);
        if (response) {
          toast.success('Register successful');

          navigate(RouteBasePaths.HomePage);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[508px] mx-auto form-group become-a-member'>
        <div className='form-item'>
          <label htmlFor='email'>Deine E-Mail-Adresse</label>
          <Input type='email' id='email' name='email' value={formik.values.email} onChange={formik.handleChange} />
          {formik.touched.email && formik.errors.email ? <span className='form-error'>{formik.errors.email}</span> : <></>}
        </div>

        <div className='form-item'>
          <label htmlFor='date'>Datum</label>
          <DatePickerComp
            isBlackCalendarIcon={true}
            selected={formik.values.date}
            placeholder='dd.MM.yyyy'
            onChange={(date) => formik.setFieldValue('date', date)}
            className='become-a-member__date-picker'
          />
          {formik.touched.date && formik.errors.date ? <span className='form-error'>{formik.errors.date}</span> : <></>}
        </div>

        <div className='w-full flex justify-center items-center !mt-11 sm:space-y-0 sm:justify-start'>
          <Button type='submit' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit'>
            Termin anfragen
          </Button>
        </div>
      </form>
      {isLoading ? <Loading isLoadingMask /> : <></>}
    </>
  );
};

export default BecomeAMemberForm;
