import './Loading.scss';

import SpinnerComp from 'components/spinnerComp';
import { bool, string } from 'prop-types';
import React, { useMemo } from 'react';

const Loading = ({ isPageLoading = false, isLoadingMask = false, className = '' }) => {
  const loadingContainerClass = useMemo(() => {
    return isPageLoading ? 'w-screen h-screen bg-black text-black' : 'w-full h-full text-inherit';
  }, [isPageLoading]);

  return !isLoadingMask ? (
    <div className={`flex flex-col space-y-2 justify-center items-center loading-container ${[loadingContainerClass, className].join(' ')}`}>
      <SpinnerComp size='xl' />
    </div>
  ) : (
    <div className='loading-mask'>
      <SpinnerComp size='xl' />
    </div>
  );
};

Loading.propTypes = {
  isPageLoading: bool,
  isLoadingMask: bool,
  className: string
};

export default Loading;
