import Logo from 'assets/images/logo-colorful.png';
import Loading from 'components/loading';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import RegisterForm from 'pages/authenticatePage/registerPage/registerFormPopup/RegisterForm';
import { useCloseAuthenticatePage } from 'pages/authenticatePage/useCloseAuthenticatePage';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRegister } from 'services/authenticate';

const RegisterFormPopup = ({ isOpenRegisterForm, setIsOpenRegisterForm }) => {
  const navigate = useNavigate();
  const handleClosePopup = useCloseAuthenticatePage();
  const registerService = useRegister();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async (values) => {
    setIsLoading(true);
    try {
      const response = await registerService(values);

      if (response) {
        setIsOpenRegisterForm(false);
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalComp isOpen={isOpenRegisterForm} onClose={handleClosePopup}>
        <div className='w-full font-sans space-y-6 sm:space-y-11'>
          <div className='w-full space-y-3.5 sm:space-y-7'>
            <div className='w-full max-w-[134px] mx-auto'>
              <div className='w-full pb-100 relative'>
                <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
              </div>
            </div>
            <div className='w-full'>
              <h6 className='uppercase text-center font-medium'>Registrieren</h6>
              <p className='w-full text-center'>
                Hast Du schon einen Account?{' '}
                <span className='link-style' onClick={() => navigate(RouteBasePaths.AuthenticatePage)}>
                  Login.
                </span>
              </p>
            </div>
          </div>
          <RegisterForm onSubmit={handleRegister} />
        </div>
      </ModalComp>
      {isLoading ? <Loading isLoadingMask /> : <></>}
    </>
  );
};

RegisterFormPopup.propTypes = {
  isOpenRegisterForm: bool,
  setIsOpenRegisterForm: func
};

export default RegisterFormPopup;
