import LogoIcon from 'assets/images/logo-white.svg';
import RouteBasePaths from 'constants/RouteBasePaths';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AdminLogo = () => {
  const navigate = useNavigate();

  const adminPage = useMemo(() => {
    return RouteBasePaths.AdminAccountPage;
  }, []);

  return (
    <div className='text-white flex justify-start items-center cursor-pointer transition-ease-in-out z-40 '>
      <div className='w-14 xl:w-28' onClick={() => navigate(adminPage)}>
        <div className='w-full pb-100 relative'>
          <img src={LogoIcon} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
        </div>
      </div>
      <Link to={adminPage} className='uppercase text-2xl whitespace-nowrap sm:text-3xl 2xl:text-32px'>
        the kingdom | admin
      </Link>
    </div>
  );
};

export default AdminLogo;
