import Logo from 'assets/images/logo-colorful.png';
import Button from 'components/button';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SentTokenSuccessPopup = ({ isOpen, email }) => {
  const navigate = useNavigate();
  const handleClosePopup = () => navigate(RouteBasePaths.AuthenticatePage);

  return (
    <ModalComp isOpen={isOpen} onClose={handleClosePopup}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
          <div className='w-full max-w-xs mx-auto'>
            <h6 className='uppercase text-center font-medium'>Check email for reset link</h6>
            <p className='w-full text-center'>
              An email has been sent to <strong>{email}</strong>. Check the inbox of your mail and click the reset password link provided.
            </p>
          </div>
        </div>
        <div className='w-full flex justify-center items-center space-y-4 !mt-11 flex-col'>
          <Button type='button' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit' onClick={handleClosePopup}>
            Continue
          </Button>
        </div>
      </div>
    </ModalComp>
  );
};

SentTokenSuccessPopup.propTypes = {
  isOpen: bool,
  email: string,
  onClose: func
};

export default SentTokenSuccessPopup;
