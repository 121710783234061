import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useGetResetPasswordToken = () => {
  return useCallback(async (email) => {
    return await axiosInstance.post(`${AUTHENTICATE_BASE_URL}/get-reset-password-token`, {
      email: email
    });
  }, []);
};

export default useGetResetPasswordToken;
