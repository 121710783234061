import HeaderButtons from 'components/header/headerButtons';
import { headerNavLink } from 'components/header/hompageHeader/configs';
import { number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HamburgerButton = ({ headerHeight }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const handleControlHeaderMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <button className={`hamburger-button ${isOpen ? 'active' : ''}`} onClick={handleControlHeaderMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div
        className={`fixed w-screen h-screen bg-primary transition-ease-in-out top-0 flex flex-col justify-center items-center space-y-14 sm:space-y-28 ${
          isOpen ? 'left-0' : '-left-full'
        }`}
        style={{ paddingTop: `${headerHeight}px`, paddingBottom: `${headerHeight}px` }}
      >
        <div className='flex flex-col justify-center items-center space-y-6 text-white sm:space-y-12'>
          {headerNavLink.map((link) => (
            <NavLink key={link.path} to={link.path} className='uppercase nav-link'>
              {link.label}
            </NavLink>
          ))}
        </div>
        <HeaderButtons />
      </div>
    </>
  );
};

HamburgerButton.propTypes = {
  headerHeight: number
};

export default HamburgerButton;
