import './FoundedTrader.scss';

import PageContainer from 'components/pageContainer';
import Title from 'components/title';
import { BreakPoints } from 'constants/index';
import { useWindowSize } from 'hooks/index';
import {
  foundedTrader10,
  foundedTrader20,
  foundedTrader25,
  foundedTrader35,
  foundedTrader40,
  foundedTrader50,
  foundedTrader60,
  foundedTrader80,
  foundedTrader100,
  foundedTrader110,
  foundedTrader125,
  foundedTrader160,
  foundedTrader185,
  foundedTrader200,
  foundedTrader1100} from 'pages/client/foundedTraderPage/configs';
import FoundedTraderSwiper from 'pages/client/foundedTraderPage/FoundedTraderSwiper';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'stores/layout/selector';

const FoundedTraderPage = () => {
  const { headerHeight } = useSelector(layoutSelector);
  const { width } = useWindowSize();
  const lineSvgRef = useRef(null);
  const linePathRef = useRef(null);
  const sectionContainerRef = useRef(null);
  const [pathLength, setPathLength] = useState(0);
  const [firstCircleRef, setFirstCircleRef] = useState(null);

  useLayoutEffect(() => {
    if (lineSvgRef?.current && firstCircleRef?.current) {
      const handleSetSvgStyle = () => {
        const borderWidth = 2;
        const firstCirCleOffsetTop = firstCircleRef.current.offsetTop;

        lineSvgRef.current.style.top = `${firstCirCleOffsetTop + borderWidth}px`;
      };

      document.addEventListener('resize', handleSetSvgStyle);

      handleSetSvgStyle();

      return () => document.removeEventListener('resize', handleSetSvgStyle);
    }
  }, [lineSvgRef, firstCircleRef, headerHeight, width]);

  useLayoutEffect(() => {
    if (linePathRef?.current && sectionContainerRef?.current) {
      const linePathCurrent = linePathRef.current;
      const sectionItemPadding = width >= BreakPoints.BreakpointSm ? 28 : 14;
      const linePathLength = sectionContainerRef.current.offsetHeight - headerHeight - sectionItemPadding * 2;

      setPathLength(linePathLength);

      linePathCurrent.style.strokeDasharray = linePathLength + ' ' + linePathLength;

      linePathCurrent.style.strokeDashoffset = linePathLength;

      window.addEventListener('scroll', () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const drawLength = linePathLength * scrollPercentage;

        // INFO:  draw in reverse
        linePathCurrent.style.strokeDashoffset = linePathLength - drawLength;
      });
    }
  }, [linePathRef, sectionContainerRef]);

  return (
    <div className='min-h-screen w-full flex flex-col relative bg-top founded-trader' style={{ padding: `${headerHeight}px 0` }}>
      <PageContainer>
        <Title title='THE HALL OF FAME UNSERER FREMDKAPITAL TRADER' subtitle='We are creating success storys together - One dream, one family!' />
      </PageContainer>
      <div ref={sectionContainerRef} className='w-full space-y-20 relative xl:space-y-40'>
        <div ref={lineSvgRef} className='founded-trader__line'>
          <svg
            width='11'
            height={pathLength}
            viewBox={`0 0 11 ${pathLength}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='xMidYMax meet'
          >
            <path ref={linePathRef} d={`M5.5 0V${pathLength}`} stroke='url(#paint0_linear_33_2)' strokeWidth='10' />
            <defs>
              <linearGradient id='paint0_linear_33_2' x1='-2.32939e-07' y1='2187.96' x2='11' y2='2187.96' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#988313' stopOpacity='0' />
                <stop offset='0.510417' stopColor='#988313' />
                <stop offset='1' stopColor='#988313' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <FoundedTraderSwiper setRef={setFirstCircleRef} data={foundedTrader1100} dataName='Funded Trader 1.100.000$' />
        <FoundedTraderSwiper data={foundedTrader200} dataName='Funded Trader 200.000$' />
        <FoundedTraderSwiper data={foundedTrader185} dataName='Funded Trader 185.000$' />
        <FoundedTraderSwiper data={foundedTrader160} dataName='Funded Trader 160.000$' />
        <FoundedTraderSwiper data={foundedTrader125} dataName='Funded Trader 125.000$' />
        <FoundedTraderSwiper data={foundedTrader110} dataName='Funded Trader 110.000$' />
        <FoundedTraderSwiper data={foundedTrader100} dataName='Funded Trader 100.000$' />
        <FoundedTraderSwiper data={foundedTrader80} dataName='Funded Trader 80.000$' />
        <FoundedTraderSwiper data={foundedTrader60} dataName='Funded Trader 50.000$' />
        <FoundedTraderSwiper data={foundedTrader50} dataName='Funded Trader 50.000$' />
        <FoundedTraderSwiper data={foundedTrader40} dataName='Funded Trader 40.000$' />
        <FoundedTraderSwiper data={foundedTrader35} dataName='Funded Trader 35.000$' />
        <FoundedTraderSwiper data={foundedTrader25} dataName='Funded Trader 25.000$' />
        <FoundedTraderSwiper data={foundedTrader20} dataName='Funded Trader 20.000$' />
        <FoundedTraderSwiper data={foundedTrader10} dataName='Funded Trader 10.000$' />
      </div>
    </div>
  );
};

export default FoundedTraderPage;
