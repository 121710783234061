export const leaderData = [
  {
    name: 'Müslüm Demir',
    position: 'Director',
    // description: 'Stars Prove you that they can shine in the dark and you were born to shine bright',
    fileId: '1aGiGYXIDCyHpX_PsGZg4ilI9HV-aa4zw',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/muesluemdemir/?igshid=YmMyMTA2M2Y%3D'
      }
    ]
  },
  {
    name: 'Joshua Badder',
    position: 'E5000',
    // description: 'Practice like you Never won But Play like you have Never lost',
    fileId: '1whPmsZsM7Br_76c_0cp7MryDxh6LjhHY',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/joshuabadder/?igshid=YmMyMTA2M2Y%3D'
      }
    ]
  },
  {
    name: 'Muhammed Ali Kaplan',
    position: 'E5000',
    // description: 'In the end, it’s not the years in your life that count. It’s the life in your years.',
    fileId: '1u91qmAS2MUFlzfalfm2thqg15LZo25PM',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/imuhammedali_?igshid=MzRlODBiNWFlZA=='
      }
    ]
  },
  // {
  //   name: 'Kaan Pekcan',
  //   position: 'E2000',
  //   // description: 'If you want to be the best, you can’t work like the rest',
  //   fileId: '1AJGYTtiOQlb8A1VSbD2YBgsR9jyFv1Ja',
  //   social: [
  //     {
  //       type: 'instagram',
  //       link: 'https://www.instagram.com/kaanpekcanofficial/'
  //     }
  //   ]
  // },
  {
    name: 'Alessio Burgio',
    position: 'E2000',
    // description: 'mach andere Menschen erfolgreich und für dich wird gesorgt sein',
    fileId: '1S_tAKeNlLSveShLShz9dXMmkPkTYnME-',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/imalessio.b?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Ali Narin',
    position: 'E2000',
    // description: 'A person with a burning Desire, backed by Faith, doesn\'t know the word Impossible.',
    fileId: '19SSGI72d7rMgNjr7cxS3z6RnawhSN0Te',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/ali.narin1?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Julia Marie',
    position: 'E2000',
    // description: 'Be the change you want to see in the world',
    fileId: '1MtuaH5tkYxctWjxkRdNyK5vPETa70ues',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/im.juliamarie?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Adem Calik',
    position: 'E2000',
    // description: 'No rich parents. No investors. No plan B. No excuses.',
    fileId: '1ojaZRqCx-g3mcPGCXVjoW10eFZdPVSN7',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/offizielladem?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Ben Pötzsch',
    position: 'E2000',
    // description: 'Öffne der Veränderung die Arme, aber verliere dabei deine Werte nicht aus den Augen.',
    fileId: '1i4sIBtaZzFnOoxsLEVEmuo3Y4Yn79QcT',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/ben_poe?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Nicole Krizkova',
    position: 'E2000',
    // description: 'Happiness is a choice',
    fileId: '1nUUzyTVhVVXYUQJN_YKyamOp8V-m4u5T',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/nicolekrizk/'
      }
    ]
  },
  {
    name: 'Yasemin Demir',
    position: 'E2000',
    // description: 'Deine Ausreden sind alle berechtigt. Aber keine davon wird dich ans Ziel bringen.',
    fileId: '1pRmXGtNX4nKhfBdxnuGbKFHcCSI_VRcz',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/_yasemin.demir?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Yagmur Dumanli',
    position: 'E2000',
    // description: 'I DIDN\'T WAKE UP TO BE AVERAGE.',
    fileId: '1DSTE59Z0eyDlfz9ri3Dq8S4cfXyMR2vI',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/yagmur.dmnl?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'
      }
    ]
  },
  // {
  //   name: 'Marco Hechler',
  //   position: ' P1000',
  // //   description: 'What‘s hard to do is easy to live with and what’s easy to do is hard to live with, you choose!',
  //   fileId: '1mY6OI0bgwf0yFTddb0PWQK9dJuY5P8RR',
  //   social: [
  //     {
  //       type: 'instagram',
  //       link: 'https://instagram.com/marco.impact?igshid=YmMyMTA2M2Y='
  //     }
  //   ]
  // },
  {
    name: 'Yavuz Cakal',
    position: 'E2000',
    // description: 'Sobald ich denke ich bin etwas, dann bin ich es nicht mehr. Ich limitier mich nicht',
    fileId: '1RudKN9H8dtimkqXUzMWoVfxBhNVn7Mqm',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/yavuz.ca?igsh=dDI3YTk1bzFqZ2dn'
      }
    ]
  },
  {
    name: 'Cimberly Visconti Di Verona',
    position: 'E1000',
    // description: 'We don\'t have problems, we only have challenges',
    fileId: '1uQcdW77HwNv4nWbtPfR3Utr8PMDDUXBp',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/im.cimberly/'
      }
    ]
  },
  {
    name: 'Fabion Morina',
    position: 'E1000',
    // description: 'Arbeite 3 Jahre wie niemand will, um danach das Leben so zu leben wie niemand kann.',
    fileId: '1Yt1i-XY93wFWMQUKZwlD0QGgKGnK4aKj',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/fabion.morina?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  // {
  //   name: 'Katharina Gramling',
  //   position: 'E1000',
  // //   description: 'Manche Menschen träumen vom Erfolg, während andere jeden Morgen aufstehen und ihn
  // verwirklichen', fileId: '1TolRmIH80kk5IMTpi_2g3HKkizUhHbiH', social: [ { type: 'instagram', link:
  // 'https://www.instagram.com/kathigramling/' } ] },
  {
    name: 'Julian Jejakody',
    position: 'E1000',
    // description: 'Auch wenn die Welt gegen dich steht solang du Gott an deiner Seite hast kannst du nicht verlieren',
    fileId: '1VbeADqiFwwqnu4jFPKKcWmzInibz76vr',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/julianjkd?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  {
    name: 'Jean-Marc Kapela',
    position: 'E1000',
    // description: 'We walk by faith, not by sight 🕊️',
    fileId: '1voMjaD2sxWmvs49tCv7p86SkC-tthgLn',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/monsieur_nour/'
      }
    ]
  },
  {
    name: 'Calogero Casa',
    position: 'E1000',
    // description: 'God has the most amazing plan for you.',
    fileId: '17e_gDaQQCQadEolXBEkyKF-R4H8iQirw',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/im.calogerocasa?igshid=YmMyMTA2M2Y='
      }
    ]
  },
  // {
  //   name: 'Niko Schmidt',
  //   position: 'E1000',
  //   // description: 'Deine Ausreden sind alle berechtigt. Aber keine davon wird dich ans Ziel bringen.',
  //   fileId: '131CQerDN7xRHsWbNdpf6i5HeaoSiFn7v',
  //   social: [
  //     {
  //       type: 'instagram',
  //       link: 'https://instagram.com/travelnikoo?igshid=MmJiY2I4NDBkZg=='
  //     }
  //   ]
  // },
  // {
  //   name: 'Patrick Hechler',
  //   position: 'E1000',
  // //   description: 'Be All In or be nothing at all',
  //   fileId: '19XPBUFONfUagH39Z6lMPxkKwIVc-yzV6',
  //   social: [
  //     {
  //       type: 'instagram',
  //       link: 'https://www.instagram.com/impact.paddy/'
  //     }
  //   ]
  // },
  {
    name: 'Jonas Yunus Grimmelsmann',
    position: 'E1000',
    // description: 'History is written by the winners',
    fileId: '1aqtUZFHesp1gvK9IlgeQ2PXyraGAo-Fp',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/jonas_grimmelsmann/'
      }
    ]
  },
  {
    name: 'Batuhan Özden',
    position: 'E1000',
    // description: 'Be the change you wish to see in the world',
    fileId: '1CV-S5SXcNyaJTiqhv6Wg7zxcNBhpZolo',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/batuoezden?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'
      }
    ]
  },
  {
    name: 'Katrin Griesbauer',
    position: 'E1000',
    // description: 'Vergiss nicht deine Sonnenbrille aufzusetzen, denn deine Zukunft strahlt ☀️',
    fileId: '1Hh2b2XCsGsTEen8fN-cGTJIqIoMnzby9',
    social: [
      {
        type: 'instagram',
        link: 'https://instagram.com/itskatriin?igshid=MzRlODBiNWFlZA=='
      }
    ]
  },
  {
    name: 'Nicole Waser',
    position: 'E1000',
    // description: 'Raise your standards and life will meet you there.',
    fileId: '1NsgTbbRciXRdaDZjgdYTTGzOxZs7VR8B',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/niciwaser?igsh=MTB3am91bDBmc3V0eQ=='
      }
    ]
  },
  // {
  //   name: 'Kristina Schmidt',
  //   position: 'E1000',
  //   // description: 'Remember who you are.',
  //   fileId: '1DkZv0YR2mnk3Jbgx39Cox5uM3vHRdeRp',
  //   social: [
  //     {
  //       type: 'instagram',
  //       link: 'https://instagram.com/kris.tina_schm.idt?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr'
  //     }
  //   ]
  // },
  {
    name: 'Dilara Balik',
    position: 'E1000',
    // description: '',
    fileId: '1iA6W7VgpVMXyTYpqDa_QlI9a1BBEIx9Z',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/dilaraablk_?igsh=ZjU3YWZpMGF1MDlv'
      }
    ]
  },
  {
    name: 'Miralem Nakicevic',
    position: 'E1000',
    // description: '',
    fileId: '1JAnnhECZomq-yu7aRSkI05ePaVPDwkL5',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/miralem.nakicevic?igsh=MTQ1N3NxMmRidXoydg=='
      }
    ]
  },
  {
    name: 'Assvan Mohanaraajah',
    position: 'E1000',
    // description: '',
    fileId: '1e4XzCmQgSumAhkZtqap6EDtYTEncackF',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/im.assvan?igsh=MW1mNDNxYWx0bmdxbQ=='
      }
    ]
  },
  {
    name: 'Joel Matteo Jiménez Rodriquens',
    position: 'E1000',
    // description: '',
    fileId: '1zKyIUJX-1q1xdtd7NaoWzfhfuI0oeELn',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/joelmatteo?igsh=M2dwc2tmbjZrYnEy'
      }
    ]
  },
  {
    name: 'Chris Krakl',
    position: 'E1000',
    // description: '',
    fileId: '1ohTTSMMg80w_0rptiQBuUZ1OPtqC6lkw',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/chris_krakl?igsh=MWhvejM4dXM5NHl3bg=='
      }
    ]
  },
  {
    name: 'Selina Özdemir',
    position: 'E1000',
    // description: '',
    fileId: '1UTrOkXlM_0wrQOYAvf98BxFo2fpDzsio',
    social: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/selina.oezdemir?igsh=MTJidTRpbmJiYWhy'
      }
    ]
  }
];
