import { string } from 'prop-types';
import React from 'react';

const Title = ({ title, subtitle, titleTag = 'h1', subtitleTag = 'h2', rootClassName = '', titleClassName = '', subtitleClassName = '' }) => {
  const TitleTagName = titleTag;
  const SubtitleTagName = subtitleTag;

  return (
    <div className={`w-full space-y-10 lg:space-y-20 ${rootClassName}`}>
      {title ? (
        <TitleTagName className={`uppercase text-shadow-black-85 text-white-85 text-3xl sm:text-4xl xl:text-6xl mb-8 text-center ${titleClassName}`}>
          {title}
        </TitleTagName>
      ) : (
        <></>
      )}
      {subtitle ? (
        <SubtitleTagName
          className={`text-white-85 text-center !leading-snug drop-shadow-black-85 text-xl sm:text-2xl lg:text-3xl ${subtitleClassName}`}
        >
          {subtitle}
        </SubtitleTagName>
      ) : (
        <></>
      )}
    </div>
  );
};

Title.propTypes = {
  title: string,
  subtitle: string,
  titleTag: string,
  subtitleTag: string,
  rootClassName: string,
  titleClassName: string,
  subtitleClassName: string
};

export default Title;
