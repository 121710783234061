import Container from 'components/container';
import Image from 'components/image';
import SwiperComp from 'components/swiperComp';
import Title from 'components/title';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { leaders } from './configs';

const LeaderSlider = () => {
  return (
    <section className='w-full relative py-10 home-page__leader-slider'>
      <Container className='space-y-10 xl:space-y-20'>
        <Title title='leader' titleTag='h6' />
        <SwiperComp
          slidesPerView={1}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50
            }
          }}
          navigation={true}
          spaceBetween={60}
        >
          {leaders.map((leader, index) => (
            <SwiperSlide key={index} className='flex flex-col justify-center items-center space-y-8'>
              <div className='w-full max-w-[400px] rounded-2xl overflow-hidden'>
                <div className='w-full pb-459-597 relative'>
                  <Image
                    fileId={leader.fileId}
                    alt={leader.title}
                    className='absolute w-full h-full inset-0 object-cover'
                    loadingClassName='absolute'
                  />
                </div>
              </div>
              <div className='w-full text-center text-white-85 space-y-2'>
                <h6 className='drop-shadow-black-85 text-xl xl:text-2xl'>{leader.title}</h6>
                <p className='drop-shadow-black-85 sm:text-lg'>{leader.subtitle}</p>
              </div>
            </SwiperSlide>
          ))}
        </SwiperComp>
      </Container>
    </section>
  );
};

export default LeaderSlider;
