import Logo from 'assets/images/logo-colorful.png';
import Button from 'components/button';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import { bool, func } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerifySuccessModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <ModalComp isOpen={isOpen} onClose={onClose}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
        </div>
        <div className='w-full text-center max-w-[330px] mx-auto'>
          <h6 className='font-medium'>Verify Email Success</h6>
          <p>Your email has been verified success!</p>
        </div>
        <div className='w-full flex justify-center items-center flex-col space-y-4 !mt-11 sm:space-y-0 sm:flex-row sm:space-x-9'>
          <Button className='bg-transparent border-primary w-full max-w-sm !text-primary hover:!text-white sm:w-fit' onClick={onClose}>
            Homepage
          </Button>
          <Button className='w-full sm:w-fit' onClick={() => navigate(RouteBasePaths.AuthenticatePage)}>
            Login
          </Button>
        </div>
      </div>
    </ModalComp>
  );
};

VerifySuccessModal.propTypes = {
  isOpen: bool,
  onClose: func
};

export default VerifySuccessModal;
