import Footer from 'components/footer';
import Header from 'components/header';
import SocialAndScrollTop from 'pages/client/homePage/SocialAndScrollTop';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ClientLayout = () => {
  return (
    <div className='min-h-screen w-full flex flex-col overflow-hidden relative'>
      <Header />
      <Outlet />
      <Footer />
      <SocialAndScrollTop />
    </div>
  );
};

export default ClientLayout;
