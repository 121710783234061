import 'react-datepicker/dist/react-datepicker.css';

import DatePickerInput from 'components/datePickerComp/DatePickerInput';
import { bool, string } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';

const DatePickerComp = ({ className = '', isBlackCalendarIcon = false, ...props }) => {
  return (
    <div className={`w-full date-picker ${className}`}>
      <DatePicker
        {...props}
        dateFormat='dd.MM.yyyy'
        customInput={<DatePickerInput isCalendarBlack={isBlackCalendarIcon} />}
        className='date-picker__input'
      />
    </div>
  );
};

DatePickerComp.propTypes = {
  className: string,
  placeholder: string,
  isBlackCalendarIcon: bool
};

export default DatePickerComp;
