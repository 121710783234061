import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const generateWindowSize = () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });

    window.addEventListener('resize', generateWindowSize);

    generateWindowSize();

    return () => window.removeEventListener('resize', generateWindowSize);
  }, []);

  return windowSize;
};

export default useWindowSize;
