import './HomePage.scss';

import DividerAnimation from 'components/dividerAnimation';
import DataCountSection from 'pages/client/homePage/DataCountSection';
import FirstSectionGroup from 'pages/client/homePage/firstSectionGroup';
import LeaderSlider from 'pages/client/homePage/LeaderSlider';
import SloganSection from 'pages/client/homePage/SloganSection';
import WorkWithUseSection from 'pages/client/homePage/WorkWithUseSection';
import React from 'react';

const HomePage = () => {
  return (
    <div className='home-page'>
      <FirstSectionGroup />
      <SloganSection />
      <DataCountSection />
      <WorkWithUseSection />
      <DividerAnimation />
      <LeaderSlider />
    </div>
  );
};

export default HomePage;
