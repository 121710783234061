import ScrollToTopButton from 'components/scrollToTopButton';
import SocialButtons from 'components/socialButtons';
import React from 'react';

const SocialAndScrollTop = () => {
  return (
    <div className='fixed space-y-6 right-4 bottom-8 z-40 hidden sm:right-6 sm:bottom-10 md:block'>
      <SocialButtons type='vertical' />
      <ScrollToTopButton />
    </div>
  );
};

export default SocialAndScrollTop;
