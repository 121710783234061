import { SvgIcons } from 'assets/images/SvgIcons';

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      type='button'
      title='Scroll To Top'
      className='w-12 h-12 bg-white rounded-full flex justify-center items-center transition-ease-in-out hover:bg-hover-link hover:text-white'
      onClick={handleScrollToTop}
    >
      {SvgIcons.ArrowUp}
    </button>
  );
};

export default ScrollToTopButton;
