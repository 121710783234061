import { Spinner } from 'flowbite-react';
import { string } from 'prop-types';
import React from 'react';

const SpinnerComp = ({ className = '', ...props }) => {
  return <Spinner {...props} className={`fill-primary ${className}`} />;
};

SpinnerComp.propTypes = {
  className: string
};

export default SpinnerComp;
