import RouteBasePaths from 'constants/RouteBasePaths';

export const STEP_ONE_KEY = RouteBasePaths.LoggedTradingPage;

export const STEP_TWO_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingStepTwoPage}`;

export const STEP_THREE_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingStepThreePage}`;

export const STEP_FOUR_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingStepFourPage}`;

export const STEP_FIVE_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingStepFivePage}`;

export const STEP_BOOTCAMP_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingBootcampPage}`;
// export const STEP_ADVANCED_KEY = `${RouteBasePaths.LoggedTradingPage}/${RouteBasePaths.LoggedTradingAdvancedPage}`;

export const tradingSteps = {
  [STEP_ONE_KEY]: {
    id: '80abd274-0e43-4b45-9861-da71d1f448d5',
    name: 'Step 1',
    title: 'Vorbereitung'
  },
  [STEP_TWO_KEY]: {
    id: '54bb725e-97fb-4e85-af5d-2f40931b476e',
    name: 'Step 2',
    title: 'Trading Academy'
  },
  [STEP_THREE_KEY]: {
    id: '4a675884-674a-4404-a037-c040ee97d0fd',
    name: 'Step 3',
    title: 'Get Paid'
  },
  [STEP_FOUR_KEY]: {
    id: 'ae75221c-5b79-409b-923e-1d1db783e546',
    name: 'Step 4',
    title: 'Passives Einkommen'
  },
  [STEP_FIVE_KEY]: {
    id: '231edd16-6876-479e-9250-2b83a65ee4d3',
    name: 'Step 5',
    title: 'Strategien'
  },
  [STEP_BOOTCAMP_KEY]: {
    id: 'e7eecae6-eea6-4b72-a251-eef0e0bb13a7',
    name: 'Bootcamp',
    title: 'Bootcamp'
  }
};
