import { bool, oneOf, string } from 'prop-types';
import { forwardRef, useMemo } from 'react';

const Button = forwardRef(({ className = '', variant = 'primary', active, ...props }, ref) => {
  const buttonVariantStyle = useMemo(() => {
    let variantClassName = '';
    switch (variant) {
      case 'secondary':
        variantClassName = 'bg-white text-black border-white hover:text-white hover:border-primary hover:bg-primary/50';
        break;

      case 'tertiary':
        variantClassName = 'text-white border-white bg-transparent hover:text-white hover:border-primary hover:bg-primary/50';
        break;
      default:
        variantClassName = 'bg-primary border-primary text-white shadow-black-65 hover:text-white hover:bg-primary/50';
    }

    return variantClassName;
  }, [variant]);

  return (
    <button
      ref={ref}
      {...props}
      className={`py-3 px-4 min-w-[150px] border-2 uppercase font-normal rounded-[30px] transition-ease-in-out ${buttonVariantStyle} ${className} ${
        active ? '!bg-primary !border-primary text-white' : ''
      }`}
    />
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  className: string,
  variant: oneOf(['primary', 'secondary', 'tertiary']),
  active: bool
};

export default Button;
