import { AccountStatus } from 'constants/index';
import RouteBasePaths from 'constants/RouteBasePaths';
import AdminAccountPage from 'pages/admin/accountPage';
import AdminRegisterMemberPage from 'pages/admin/registerMemberPage';
import AuthenticatePageLayout from 'pages/authenticatePage';
import ResetPasswordPage from 'pages/authenticatePage/authenticate/resetPasswordPage';
import LoginPage from 'pages/authenticatePage/loginPage';
import RegisterPage from 'pages/authenticatePage/registerPage';
import ClientLayout from 'pages/client';
import BecomeAMemberPage from 'pages/client/becomeAMember';
import DateProtectionPage from 'pages/client/dataProtectionPage';
import FoundedTraderPage from 'pages/client/foundedTraderPage';
import HomePage from 'pages/client/homePage';
import ImpressumPage from 'pages/client/impressumPage';
import LeaderPage from 'pages/client/leaderPage';
import VerifyEmailPage from 'pages/client/verifyEmailPage';
import LoggedPageLayout from 'pages/loggedPage';
import WhatWaitForYouPage from 'pages/loggedPage/whatWaitForYouPage';
import PageNotFound from 'pages/pageNotFound';
import TradingPage from 'pages/tradingPage';
import { lazy } from 'react';

const TradingStepOnePage = lazy(() => import('pages/tradingPage/tradingStepOnePage'));
const TradingStepTwoPage = lazy(() => import('pages/tradingPage/tradingStepTwoPage'));
const TradingStepThreePage = lazy(() => import('pages/tradingPage/tradingStepThreePage'));
const TradingStepFourPage = lazy(() => import('pages/tradingPage/tradingStepFourPage'));
const TradingStepFivePage = lazy(() => import('pages/tradingPage/tradingStepFivePage'));
const TradingBootcampPage = lazy(() => import('pages/tradingPage/bootcamps'));

const AdminAccountDetailPage = lazy(() => import('pages/admin/accountPage/accountDetailPage'));
const AdminAccountListingPage = lazy(() => import('pages/admin/accountPage/accountListing'));

const AdminRegisterMemberFormDetailPage = lazy(() => import('pages/admin/registerMemberPage/registerMemberFormDetail'));


export const routes = [
  {
    path: '',
    element: <ClientLayout />,
    roles: [],
    requiredAccessPage: false,
    children: [
      {
        path: RouteBasePaths.HomePage,
        element: <HomePage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: true
      },
      {
        path: RouteBasePaths.LeaderPage,
        element: <LeaderPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      },
      {
        path: RouteBasePaths.FoundedLeaderPage,
        element: <FoundedTraderPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      },
      {
        path: RouteBasePaths.ImpressumPage,
        element: <ImpressumPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      },
      {
        path: RouteBasePaths.DataProtectionPage,
        element: <DateProtectionPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      },
      {
        path: `${RouteBasePaths.VerifyEmailPage}/:token`,
        element: <VerifyEmailPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      },
      {
        path: RouteBasePaths.BecomeAMember,
        element: <BecomeAMemberPage />,
        roles: [],
        requiredAccessPage: false,
        isIndex: false
      }
    ]
  },
  {
    path: RouteBasePaths.AuthenticatePage,
    element: <AuthenticatePageLayout />,
    roles: [],
    children: [
      {
        path: '',
        isIndex: true,
        element: <LoginPage />,
        roles: []
      },
      {
        path: RouteBasePaths.RegisterPage,
        isIndex: false,
        element: <RegisterPage />,
        roles: []
      },
      {
        path: RouteBasePaths.ResetPasswordPage,
        isIndex: false,
        element: <ResetPasswordPage />,
        roles: []
      },
      {
        path: `${RouteBasePaths.ResetPasswordPage}/:token`,
        isIndex: false,
        element: <ResetPasswordPage />,
        roles: []
      }
    ]
  },
  {
    path: RouteBasePaths.LoggedProfilePage,
    element: <LoggedPageLayout />,
    roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
    children: [
      {
        path: RouteBasePaths.WhatWaitForYouPage,
        element: <WhatWaitForYouPage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      }
    ]
  },
  {
    path: RouteBasePaths.LoggedTradingPage,
    element: <TradingPage />,
    roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
    requiredAccessPage: true,
    children: [
      {
        path: '',
        element: <TradingStepOnePage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: true
      },
      {
        path: RouteBasePaths.LoggedTradingStepTwoPage,
        element: <TradingStepTwoPage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      },
      {
        path: RouteBasePaths.LoggedTradingStepThreePage,
        element: <TradingStepThreePage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      },
      {
        path: RouteBasePaths.LoggedTradingStepFourPage,
        element: <TradingStepFourPage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      },
      {
        path: RouteBasePaths.LoggedTradingStepFivePage,
        element: <TradingStepFivePage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      },
      {
        path: RouteBasePaths.LoggedTradingBootcampPage,
        element: <TradingBootcampPage />,
        roles: [AccountStatus.USER_ROLE, AccountStatus.ADMIN_ROLE],
        requiredAccessPage: true,
        isIndex: false
      }
    ]
  },
  {
    path: RouteBasePaths.AdminAccountPage,
    element: <AdminAccountPage />,
    roles: [AccountStatus.ADMIN_ROLE],
    children: [
      {
        path: '',
        isIndex: true,
        element: <AdminAccountListingPage />,
        roles: [AccountStatus.ADMIN_ROLE]
      },
      {
        path: `${RouteBasePaths.AdminAccountDetailPage}/:userId`,
        element: <AdminAccountDetailPage />,
        isIndex: false,
        roles: [AccountStatus.ADMIN_ROLE]
      }
    ]
  },
  {
    path: RouteBasePaths.AdminRegisterMemberPage,
    element: <AdminRegisterMemberPage />,
    roles: [AccountStatus.ADMIN_ROLE],
    children: [
      {
        path: `${RouteBasePaths.AdminAccountDetailPage}/:formId`,
        element: <AdminRegisterMemberFormDetailPage />,
        isIndex: false,
        roles: [AccountStatus.ADMIN_ROLE]
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />,
    children: []
  }
];
