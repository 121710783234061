export const foundedTrader10 = [
  '1afqGoaKYdT5u3kKLo64BjP90z1GjnoUZ',
  '1_btO1Nu8wsGcpcShGQ7fzUAcWvG_v8un',
  '1t11tnzlh0NGTEHtdqFZ6jlfWI1kgIBz0',
  '1p96Eypdc5MmI_fEiBa4p_jhWMkxlfe1-',
  '1COj0M5xefqp4xS1YgB8XMl6cmCZUSI1J',
  '1wbaoUyHPU3hH6jgSmkgAXxeWRKPdhim_',
  '1zvFCWo5gMqltI_vFzxCmONEjMgerNHuR',
  '1XXVe-hun-sSt2WcjLArdgBG58NnVgIiL',
  '1jlgvGbN0LSb1IfKJC745c7ph-wa05p3f',
  '1YmVo1Xtp6RWLNZ7fVsaBO3hcLBHtx9ag',
  '1HRgzOf8Zx_GSa6Ya5ffU8xXc3yIFh6CO',
  '10fbheTbmYyfPiy-Fq-n6wPgebn7O5ZFa',
  '1lnIJvKpoCJoTGxRn0g5Rl1pfUnk5M-1a',
  '1ixVH1hz2Dz3NwpnefgSY0Upd3hb0OKjt',
  '1zL91ZdhsYCoIUUUKIC4qkDfNUl2lFMta',
  '1O6AjxfCpdK5UU3yYoyD9ZPxGaF4eBpnP',
  '1uNm6d2702Vz3tvvv70SY472rZmmt_2d-',
  '1gIdqGeNWooJAYSCMOlsyv4BBKN4ddM3S',
  '1ix1E5BAbZlgIvQitRR661sYe7RFMQ7Qc',
  '1sSHYC1oQJHCZyP0c788ZpMf887ruH8Pt',
  '1pdoHoKRH8wAh1AbcdqgRzf_2Fggh6141',
  '1yfxioGiAtbKM50NSeFoJsf0kZGzWfV0F',
  '1Ce2F3zUDaDkFFyFLfoZq4s_YynvwEXzt',
  '1W3Osjeov4tb5T-i10BUHskm58u7ARBEp',
  '14_xNPdpWV63Rcnh5G56-Z6GA7GJw_pM-',
  '1wvZCEOkOTvgDx_rwQ_kw64w8zopwyRVT',
  '1BwDsNIHmWsomnUjuxm2-yUIUtyyMG3cF',
  '1s6E6sI2e9f4BKnA_eP19YHU1serel1ze',
  '1l276D4V1w-KZTHSEK6zBS5h4NMsdSpr_',
  '19pjrP11Po0BTUZsziRpo7bbvuww7e58H',
  '1UKH7ABSnpygYZpNNiFagIl4i_JBXYAvl',
  '1uk5fF14y63Ce5eTNlupjpjKBUXUj9TRC',
  '1y4MmTEo7yugxizOxvdtHToXCMX_YoNEL',
  '1GzcEFWvVc2X31OAPz9iJioA7NWv5Pt0T',
  '1g7UWMIbwZWRbQc2FONRAwK6npbMBdUJC',
  '1ljg9pglvj_BLtAoD6B9e5XBTh8d3qIPc',
  '1vzz47Bbngg4-LWu4nNz9Z6WXOHIxLOsX'
];

export const foundedTrader20 = [
  '1oVDT6JE2RAUUiiJxr7Xa15_0iJuGIhEc',
  '1Gd2aCNPZDj_98QsxEXu69mSSyishRO1F',
  '1oVDT6JE2RAUUiiJxr7Xa15_0iJuGIhEc',
  '1Gd2aCNPZDj_98QsxEXu69mSSyishRO1F',
  '1oVDT6JE2RAUUiiJxr7Xa15_0iJuGIhEc',
  '1Gd2aCNPZDj_98QsxEXu69mSSyishRO1F',
  '1oVDT6JE2RAUUiiJxr7Xa15_0iJuGIhEc',
  '1Gd2aCNPZDj_98QsxEXu69mSSyishRO1F'
];

export const foundedTrader25 = [
  '11QKwAUl2z-RzgcuUjYamSQeR3n0LMSQw',
  '1X4eltAeKmLg0PeY7wMmwQwujpBd-iMIi',
  '1xeIVizGev6kIrbX3E1QGq5ys-PoFRJkn',
  '1tQlCimK176ap4YT7_Ixp6qiS2uv_Owlr',
  '173BNRzkewjOmj40jqBfBF1pTYfHt8bmf',
  '1ftv2fWmFEROsdbzMTDRhv1FXw-H8bm6H',
  '1rTSpgvCjhkwcKA7VBYR1hw-HBmF92NBd'
];

export const foundedTrader35 = ['1QHZ5EGCfe1chAKkxEacT_Vtny9OhEJud'];

export const foundedTrader40 = [
  '15_6A41H-TGrmUTn1b1d2decDrboIpXvu',
  '1yfZA3Z51JvdslCndxqLDSD4mzY_Epz9q',
  '1dmEMI0zt3yWpRANyrYNO6ff4iIfrk14W',
  '1S5gfuDSuUP3uWhcU_FZV0GHQhqlsB_bo',
  '15_6A41H-TGrmUTn1b1d2decDrboIpXvu',
  '1yfZA3Z51JvdslCndxqLDSD4mzY_Epz9q',
  '1dmEMI0zt3yWpRANyrYNO6ff4iIfrk14W',
  '1S5gfuDSuUP3uWhcU_FZV0GHQhqlsB_bo'
];

export const foundedTrader50 = [
  '1TLr2IaDM2xkpf6GWSdQBBumytLEjSt7l',
  '1phvyFcE3yAzZs9cZY6bRuhVC5gQ1aNZh',
  '1uS0EYbgJWyyI8aPYCCEF-pqs39YvXs8Q',
  '1MQgnZZ4iXJ6Dst3jYBDYDfUW160N8Ov9',
  '1HP5iPzZOu_qaqsW01U0cw7yrWrZYgon7',
  '1NHB0oSn1R5qFnLIIQO_aBufND5GMWWKR',
  '1fz_iEosW5530dwKPYFsAjxpKtQCkuGd8'
];

export const foundedTrader60 = [
  '10-63pW5dIpBGUDENgNtBIR-Vst70mZY4',
  '1tC-G5mivWtVCj8QvugAhcwxHh-PsHUMb',
  '10-63pW5dIpBGUDENgNtBIR-Vst70mZY4',
  '1tC-G5mivWtVCj8QvugAhcwxHh-PsHUMb',
  '10-63pW5dIpBGUDENgNtBIR-Vst70mZY4',
  '1tC-G5mivWtVCj8QvugAhcwxHh-PsHUMb',
  '10-63pW5dIpBGUDENgNtBIR-Vst70mZY4',
  '1tC-G5mivWtVCj8QvugAhcwxHh-PsHUMb',
  '10-63pW5dIpBGUDENgNtBIR-Vst70mZY4',
  '1tC-G5mivWtVCj8QvugAhcwxHh-PsHUMb'
];

export const foundedTrader80 = [
  '1Wlo14JSBExqxR3w8TUMQWTRORKhC3rDy',
  '1hG-rxZ7XPSvyRU-WRLKPtfMmsasGCmvF',
  '1Wlo14JSBExqxR3w8TUMQWTRORKhC3rDy',
  '1hG-rxZ7XPSvyRU-WRLKPtfMmsasGCmvF',
  '1Wlo14JSBExqxR3w8TUMQWTRORKhC3rDy',
  '1hG-rxZ7XPSvyRU-WRLKPtfMmsasGCmvF',
  '1Wlo14JSBExqxR3w8TUMQWTRORKhC3rDy',
  '1hG-rxZ7XPSvyRU-WRLKPtfMmsasGCmvF'
];

export const foundedTrader100 = [
  '1cpDXDzqHkJVbCQdr1MxNjZFqpkEyr3Kd',
  '1s6tP4M2iW_RCLwcMlLxEZ0ngZSnMcTkQ',
  '1kKjiVIq7Sr0iqLeQtjFWSLsojvSuIwqF',
  '11b3REc3JtyD9nKwYVL2eFXyOUUgQHbjL',
  '1PNKHqSlr4Smgi5GHGtJw9_X5W1prU-le',
  '1dhGuNO41CFhcr61uVkzCgP_ZslkQHoZ5',
  '1cpDXDzqHkJVbCQdr1MxNjZFqpkEyr3Kd',
  '1s6tP4M2iW_RCLwcMlLxEZ0ngZSnMcTkQ',
  '1kKjiVIq7Sr0iqLeQtjFWSLsojvSuIwqF',
  '11b3REc3JtyD9nKwYVL2eFXyOUUgQHbjL',
  '1PNKHqSlr4Smgi5GHGtJw9_X5W1prU-le',
  '1dhGuNO41CFhcr61uVkzCgP_ZslkQHoZ5',
  '1VLqn2c3HY6ZClG5RwVWLe5F5s0dG0UuV',
  '1CkJcr9JpAF_UP600LACEvEeOMUGKdeck',
  '1p835vfEoKIVkCNzTT9aRztZNhoOtUipM',
  '1hfkZgiwVTwOy1-2HBZBAAmQpr79gVea9',
  '1c-UwWV_l_x-nGVIjLR0iPmP9ujv4GopC'
];

export const foundedTrader110 = ['11fwaJHI0M0Z3tXVEDmf_RxltP-PdWEAl'];

export const foundedTrader125 = [
  '1aHnYtxKASzqEN0h7tWdHTG1F8dLo3vbF',
  '121yykZHIlCg1kzUCkVY0RrRGWj3D0h7i',
  '1aHnYtxKASzqEN0h7tWdHTG1F8dLo3vbF',
  '121yykZHIlCg1kzUCkVY0RrRGWj3D0h7i',
  '1aHnYtxKASzqEN0h7tWdHTG1F8dLo3vbF',
  '121yykZHIlCg1kzUCkVY0RrRGWj3D0h7i'
];

export const foundedTrader160 = [
  '1UUoqN54srdGOJ7ia1-DWRlP6D1Myfl0w',
  '1oK96FHH2jafVi1BbkfelhK5ekC7dv_jg',
  '1UUoqN54srdGOJ7ia1-DWRlP6D1Myfl0w',
  '1oK96FHH2jafVi1BbkfelhK5ekC7dv_jg',
  '1UUoqN54srdGOJ7ia1-DWRlP6D1Myfl0w',
  '1oK96FHH2jafVi1BbkfelhK5ekC7dv_jg',
  '1UUoqN54srdGOJ7ia1-DWRlP6D1Myfl0w',
  '1oK96FHH2jafVi1BbkfelhK5ekC7dv_jg'
];

export const foundedTrader185 = ['1fa_UHG3S7z3_WRpJ_S53fzRr-AQWuIR1'];

export const foundedTrader200 = [
  '19HjvtuwVs1D5TZ-iBjlrsxTaJNgOrEFp',
  '1ElplfT9wLxHDstsVxUyp7YZR55CxAMmX',
  '1UqZb-0PMvqatwAvy8m0v-ujtJDGDo2bI',
  '19HjvtuwVs1D5TZ-iBjlrsxTaJNgOrEFp',
  '1ElplfT9wLxHDstsVxUyp7YZR55CxAMmX',
  '1UqZb-0PMvqatwAvy8m0v-ujtJDGDo2bI'
];

export const foundedTrader1100 = ['1haFKbExquXGoLqVy5FWiA8yHMVbdiPRz'];
