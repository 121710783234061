import { oneOf, string } from 'prop-types';
import { useMemo } from 'react';

const Input = ({ className = '', variant = 'primary', ...props }) => {
  const inputClassName = useMemo(() => {
    return variant === 'primary' ? 'bg-gray border-none' : 'bg-transparent text-center border-2 border-white uppercase text-2xl sm:text-32px';
  }, [variant]);

  return <input {...props} className={`rounded-full outline-none px-4 py-2 shadow-md ${inputClassName} ${className}`} />;
};

Input.propTypes = {
  className: string,
  variant: oneOf(['primary', 'secondary'])
};

export default Input;
