import './BecomeAMember.scss';

import Logo from 'assets/images/logo-colorful.png';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import BecomeAMemberForm from 'pages/client/becomeAMember/BecomeAMemberForm';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BecomeAMemberPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClosePopup = () => {
    if (location.state?.fromPath) navigate(location.state?.fromPath);
    else navigate(RouteBasePaths.HomePage);
  };

  return (
    <ModalComp isOpen={true} onClose={handleClosePopup}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
          <p className='w-full text-center'>Vereinbare jetzt einen Termin um weitere Informationen zu erhalten!</p>
        </div>
        <BecomeAMemberForm />
      </div>
    </ModalComp>
  );
};

export default BecomeAMemberPage;
