import './ModalComp.scss';

import { Modal } from 'flowbite-react';
import { arrayOf, bool, element, func, oneOfType } from 'prop-types';
import React from 'react';

const ModalComp = ({ children, isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onClose={onClose} className='h-screen bg-black/80 flex justify-center items-center modal-comp'>
      <div className='relative w-full max-w-2xl max-h-full'>
        <div className='relative bg-white rounded-xl shadow p-4 max-h-[650px] h-full overflow-y-auto sm:px-4 sm:py-8 md:max-h-[800px]'>
          {onClose ? (
            <button
              type='button'
              className='absolute z-10 w-6 h-6 rounded-full bg-primary text-white flex justify-center items-center ml-auto transition-ease-in-out hover:bg-primary/80 right-2 top-2 sm:right-4 sm:top-4'
              data-modal-hide='staticModal'
              onClick={onClose}
            >
              <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          ) : (
            <></>
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

ModalComp.propTypes = {
  children: oneOfType([element.isRequired, arrayOf(element.isRequired)]),
  isOpen: bool,
  onClose: func
};

export default ModalComp;
