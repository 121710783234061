import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useResetPassword = () => {
  return useCallback(async ({ token, password, confirmPassword }) => {
    return await axiosInstance.patch(`${AUTHENTICATE_BASE_URL}/reset-password`, {
      token: token,
      password: password,
      confirmPassword: confirmPassword
    });
  }, []);
};

export default useResetPassword;
