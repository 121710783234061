import { useCallback } from 'react';
import { REGISTER_MEMBER_ENDPOINT } from 'services/registerMember/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useRegisterMember = () => {
  return useCallback(async (registerData) => {
    try {
      return await axiosInstance.post(`${REGISTER_MEMBER_ENDPOINT}/register`, {
        email: registerData.email,
        date: registerData.date
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }, []);
};

export default useRegisterMember;
