import Logo from 'assets/images/logo-colorful.png';
import ModalComp from 'components/modalComp';
import GetResetPasswordTokenForm from 'pages/authenticatePage/authenticate/resetPasswordPage/GetResetPasswordTokenForm';
import ResetPasswordForm from 'pages/authenticatePage/authenticate/resetPasswordPage/ResetPasswordForm';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const handleCloseResetPasswordPage = () => navigate('..', { relative: true });

  return (
    <ModalComp isOpen={true} onClose={handleCloseResetPasswordPage}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
          <div className='w-full max-w-xs mx-auto'>
            <h6 className='uppercase text-center font-medium'>Set new password</h6>
            {token ? (
              <p className='w-full text-center'>Your new password must be different to previously used passwords.</p>
            ) : (
              <p className='w-full text-center'>
                Lost your password? Please enter your email address. You will receive a link to create a new password via email
              </p>
            )}
          </div>
        </div>
        {token ? <ResetPasswordForm token={token} /> : <GetResetPasswordTokenForm />}
      </div>
    </ModalComp>
  );
};

export default ResetPasswordPage;
