export const SvgIcons = {
  Instagram: (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 25.8577C0 24.4986 0 23.1328 0 21.7737C0.0795577 21.2102 0.145856 20.64 0.238673 20.0765C1.02099 15.1439 3.12264 10.8412 6.61655 7.29421C12.1856 1.63899 18.9679 -0.727852 26.8507 0.193691C31.9424 0.790374 36.4043 2.89865 40.0772 6.45223C46.3689 12.5516 48.8683 20.0301 47.065 28.5958C45.1026 37.9107 39.2551 43.9173 30.1789 46.7614C28.7601 47.2056 27.2883 47.3979 25.8165 47.5769H21.7325C20.8176 47.4443 19.896 47.3648 18.9944 47.1526C9.97786 45.0443 3.99114 39.5814 1.0011 30.83C0.450827 29.219 0.198894 27.535 0.0066298 25.8444L0 25.8577ZM23.801 10.2909C21.6065 10.3307 19.4054 10.1583 17.2176 10.3837C15.1889 10.5892 13.4254 11.3384 12.0729 12.9229C10.7801 14.4411 10.3226 16.2577 10.2696 18.1737C10.1635 22.2378 10.0574 26.3085 10.3558 30.3726C10.5016 32.4013 11.2972 34.1648 12.8817 35.5239C14.3999 36.8167 16.2165 37.2808 18.1325 37.3272C22.1966 37.4267 26.2673 37.5328 30.3247 37.241C32.3534 37.0952 34.117 36.293 35.4695 34.7085C36.7623 33.1969 37.2264 31.3737 37.2728 29.4577C37.3788 25.3936 37.4849 21.3229 37.1866 17.2588C37.0407 15.2301 36.2451 13.4666 34.654 12.1075C33.1225 10.8014 31.286 10.3771 29.3568 10.3041C27.5004 10.2378 25.6441 10.2909 23.7877 10.2909H23.801Z'
        fill='currentColor'
      />
      <path
        d='M23.7745 12.5518C25.6773 12.5518 27.58 12.5186 29.4761 12.6446C31.2662 12.7639 32.8971 13.2214 33.9844 14.8126C34.6805 15.8335 34.8794 17.0004 34.9457 18.2004C35.1049 21.1374 35.0386 24.0744 35.0253 27.0114C35.0253 28.291 35.012 29.5771 34.7667 30.8434C34.3159 33.1572 32.6518 34.6556 30.3049 34.8942C28.1303 35.113 25.9491 35.0732 23.7679 35.0732C21.8651 35.0732 19.9624 35.0998 18.0663 34.9804C16.2762 34.8677 14.6453 34.397 13.558 32.8125C12.8685 31.8047 12.663 30.6512 12.5967 29.4711C12.4309 26.428 12.4972 23.3783 12.5171 20.3352C12.5237 19.1484 12.537 17.9551 12.769 16.7816C13.2398 14.4413 14.8839 12.9628 17.2773 12.7308C18.358 12.6247 19.432 12.5717 20.5193 12.5584C21.5999 12.5452 22.6872 12.5584 23.7679 12.5584L23.7745 12.5518ZM23.7745 16.8744C19.9226 16.8744 16.8397 19.9507 16.8397 23.7959C16.8397 27.6479 19.916 30.7373 23.7546 30.744C27.6331 30.744 30.7159 27.6677 30.7093 23.7959C30.7093 19.9507 27.6264 16.8744 23.7745 16.8744ZM31.0739 18.1076C31.9027 18.1009 32.6452 17.3451 32.6452 16.523C32.6452 15.701 31.896 14.9452 31.0673 14.9385C30.2253 14.9319 29.4563 15.7142 29.4761 16.5496C29.496 17.3849 30.2452 18.1208 31.0739 18.1076Z'
        fill='currentColor'
      />
      <path
        d='M28.3557 23.809C28.3557 26.3946 26.3535 28.3968 23.7679 28.3968C21.1823 28.3968 19.1801 26.3946 19.1801 23.809C19.1801 21.2035 21.1823 19.2212 23.8143 19.2212C26.3601 19.2212 28.3557 21.2367 28.3557 23.809Z'
        fill='currentColor'
      />
    </svg>
  ),
  Youtube: (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.8098 47.5836H21.7259C20.811 47.451 19.8894 47.3714 18.9878 47.1593C9.97123 45.051 3.98451 39.588 0.994471 30.8367C0.444197 29.2256 0.192264 27.5417 0 25.8511C0 24.4919 0 23.1262 0 21.7671C0.0795577 21.2036 0.145856 20.6334 0.238673 20.0699C1.02099 15.1373 3.12264 10.8345 6.61655 7.2876C12.179 1.63901 18.9612 -0.727833 26.8441 0.19371C31.9358 0.790392 36.3976 2.89867 40.0705 6.45225C46.3622 12.5517 48.8617 20.0301 47.0584 28.5958C45.0959 37.9107 39.2485 43.9173 30.1722 46.7615C28.7535 47.2057 27.2817 47.3979 25.8098 47.5769V47.5836ZM23.9071 34.1715C24.159 34.1582 24.5104 34.1317 24.8684 34.1251C28.0374 34.072 31.2065 34.0521 34.3623 33.714C36.4242 33.4952 37.6374 32.4411 38.0617 30.419C38.3402 29.0864 38.4264 27.7207 38.486 26.3682C38.5523 24.8102 38.5656 23.2455 38.5059 21.6809C38.4529 20.2555 38.3534 18.8434 38.1148 17.4378C37.77 15.3892 36.7092 14.3285 34.6805 13.9704C33.4739 13.7583 32.254 13.692 31.0341 13.639C28.2231 13.5196 25.4054 13.4401 22.5944 13.4865C19.4386 13.5395 16.2894 13.5594 13.1469 13.8975C11.1182 14.1163 9.90493 15.1771 9.47399 17.1726C9.28836 18.0345 9.19554 18.9163 9.13587 19.7914C8.99002 21.8002 8.95024 23.8091 9.0298 25.8179C9.08283 27.2698 9.19554 28.7151 9.41432 30.1538C9.7657 32.4411 11.2176 33.4289 12.8751 33.6543C14.0751 33.8135 15.2751 33.9195 16.4751 33.9726C18.9148 34.0786 21.3612 34.1118 23.9071 34.1715Z'
        fill='currentColor'
      />
      <path
        d='M20.7579 23.8158C20.7579 22.4832 20.7579 21.1572 20.7579 19.8246C20.7579 19.3274 20.8176 19.2942 21.2552 19.5462C23.5623 20.8721 25.8629 22.1981 28.1701 23.5307C28.601 23.7826 28.601 23.8489 28.1701 24.1008C25.8695 25.4334 23.5623 26.7594 21.2552 28.0854C20.8176 28.3373 20.7579 28.3041 20.7579 27.8135C20.7579 26.4809 20.7579 25.155 20.7579 23.8224V23.8158Z'
        fill='currentColor'
      />
    </svg>
  ),
  ArrowUp: (
    <svg width='24' height='27' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.0607 0.939341C12.4749 0.353554 11.5251 0.353554 10.9393 0.939341L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939341ZM13.5 27L13.5 2H10.5L10.5 27H13.5Z'
        fill='currentColor'
      />
    </svg>
  ),
  PlusSmall: (
    <svg fill='none' stroke='currentColor' strokeWidth='1.5' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v12m6-6H6'></path>
    </svg>
  ),
  TrianglePolygon: (
    <svg width='60' height='91' viewBox='0 0 60 91' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M60 45.5L3.42872e-06 90.1003L7.3278e-06 0.89969L60 45.5Z' fill='currentColor' />
    </svg>
  )
};
