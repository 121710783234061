import { headerNavLink } from 'components/header/hompageHeader/configs';
import { useLocation } from 'react-router-dom';

const HeaderNavLink = () => {
  const { pathname } = useLocation();

  return (
    <div className='text-white uppercase flex-1 flex justify-center items-center space-x-6 xl:space-x-20'>
      {headerNavLink.map((menu) => (
        <a key={menu.path} href={menu.path} className={['nav-link', pathname === menu.path ? 'active' : ''].join(' ')}>
          {menu.label}
        </a>
      ))}
    </div>
  );
};

export default HeaderNavLink;
