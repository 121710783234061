import PhonesImage from 'assets/images/phones.png';
import Container from 'components/container';
import { BreakPoints } from 'constants/index';
import { useIsInViewport, useWindowSize } from 'hooks/index';
import React, { useLayoutEffect, useRef } from 'react';

const WorkWithUseSection = () => {
  const { width } = useWindowSize();
  const sectionContainerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const circleSvgRef = useRef(null);
  const circlePathRef = useRef(null);
  const line1PathRef = useRef(null);
  const isOnScreen = useIsInViewport(sectionContainerRef);

  useLayoutEffect(() => {
    if (imageContainerRef?.current && circleSvgRef?.current) {
      const imageContainerCurrent = imageContainerRef.current;
      const circleSvgCurrent = circleSvgRef.current;

      const imageContainerWidth = imageContainerCurrent.offsetWidth;

      circleSvgCurrent.setAttribute('width', `${imageContainerWidth}px`);

      circleSvgCurrent.setAttribute('height', `${imageContainerWidth}px`);
    }
  }, [imageContainerRef, circleSvgRef]);

  useLayoutEffect(() => {
    if (line1PathRef?.current && circlePathRef?.current) {
      const circlePathCurrent = circlePathRef.current;
      const line1PathCurrent = line1PathRef.current;
      const circlePathLength = circlePathCurrent?.getTotalLength();
      const line1PathLength = line1PathCurrent?.getTotalLength();

      circlePathCurrent.style.strokeDasharray = circlePathLength + ' ' + circlePathLength;

      circlePathCurrent.style.strokeDashoffset = circlePathLength;

      line1PathCurrent.style.strokeDasharray = line1PathLength + ' ' + line1PathLength;

      line1PathCurrent.style.strokeDashoffset = line1PathLength;

      const scrollPercentageDefault =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      let isDrawCircle = false;

      window.addEventListener('scroll', () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);

        if (scrollPercentage >= scrollPercentageDefault - 0.02 && isOnScreen) {
          const drawLine1Length = line1PathLength * (scrollPercentage - scrollPercentageDefault);

          if (line1PathLength - drawLine1Length * 6 > line1PathLength * 0.45) {
            line1PathCurrent.style.strokeDashoffset = line1PathLength - drawLine1Length * 6;
          } else {
            line1PathCurrent.style.strokeDashoffset = line1PathLength * (width < BreakPoints.BreakpointXXl ? 0.48 : 0.45);

            isDrawCircle = true;
          }
          if (isDrawCircle || width <= BreakPoints.BreakpointXl) {
            const drawCircleLength = circlePathLength * (scrollPercentage - scrollPercentageDefault);

            if (circlePathLength + drawCircleLength * 6 <= circlePathLength * 2) {
              circlePathCurrent.style.strokeDashoffset = circlePathLength + drawCircleLength * 6;
            } else {
              circlePathCurrent.style.strokeDashoffset = circlePathLength * 2;

              line1PathCurrent.style.strokeDashoffset = line1PathLength + line1PathLength * 0.45 - drawLine1Length * 6;
            }
          }
        } else {
          circlePathCurrent.style.strokeDashoffset = circlePathLength;

          line1PathCurrent.style.strokeDashoffset = line1PathLength;
        }
      });
    }
  }, [line1PathRef, circlePathRef, width, isOnScreen]);

  return (
    <section ref={sectionContainerRef} className='w-full relative py-10 overflow-hidden xl:h-screen'>
      <Container className='w-full h-full flex justify-center items-center flex-col space-y-14 md:space-y-0 md:flex-row md:space-x-12 xl:space-x-24'>
        <div className='w-full relative md:w-1/2'>
          <div className='line-container line-container__6'>
            <svg width='1001' height='282' viewBox='0 0 1001 282' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMax meet'>
              <path ref={line1PathRef} d='M998.5 0V279H0' stroke='url(#paint0_linear_27_11)' strokeWidth='5' />
              <defs>
                <linearGradient id='paint0_linear_27_11' x1='-5491.75' y1='79.0114' x2='-5291.03' y2='-1392.17' gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#988313' stopOpacity='0' />
                  <stop offset='0.510417' stopColor='#988313' />
                  <stop offset='1' stopColor='#988313' stopOpacity='0' />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div ref={imageContainerRef} className='w-full max-w-xl mx-auto'>
            <div className='w-full pb-100 relative'>
              <img
                src={PhonesImage}
                alt='The Kingdom'
                className='absolute object-cover z-10 w-full h-full left-0 top-0 xl:w-5/6 xl:h-5/6 xl:top-1/2 xl:left-1/2 xl:-translate-x-1/2 xl:-translate-y-1/2'
              />
              <div className='line-container line-container__7'>
                <svg
                  ref={circleSvgRef}
                  width='891'
                  height='891'
                  viewBox='0 0 891 891'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  preserveAspectRatio='xMidYMax meet'
                  className='-rotate-90'
                >
                  <circle
                    ref={circlePathRef}
                    cx='445.5'
                    cy='445.5'
                    r='440.5'
                    stroke='url(#paint0_radial_25_8)'
                    strokeOpacity='0.8'
                    strokeWidth='10'
                  />
                  <defs>
                    <radialGradient
                      id='paint0_radial_25_8'
                      cx='0'
                      cy='0'
                      r='1'
                      gradientUnits='userSpaceOnUse'
                      gradientTransform='translate(446 445) rotate(90.0642) scale(446)'
                    >
                      <stop stopColor='#988313' />
                      <stop offset='0.977683' stopColor='#988313' stopOpacity='0' />
                      <stop offset='0.98881' stopColor='#988313' />
                      <stop offset='1' stopColor='#988313' stopOpacity='0' />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className='text-white-85 space-y-8 text-center md:text-left md:w-1/2'>
          <h6 className='!leading-snug drop-shadow-black-85 text-xl sm:text-2xl lg:text-3xl'>WORK WITH US</h6>
          <div className='w-full space-y-4'>
            <p className='!leading-snug text-white-85 drop-shadow-black-85 sm:text-lg'>
              Du wolltest schon immer ein Online Business starten, unabhängig sein und deine zeitliche und ortsunabhängige freiheit genießen?
            </p>
            <p className='!leading-snug text-white-85 drop-shadow-black-85 sm:text-lg'>
              Lerne bei uns wie du mit deinem Smartphone von überall aus arbeiten kannst und nutze die chance zu deinem Traumleben!
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WorkWithUseSection;
