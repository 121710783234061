import ImgBg from 'assets/images/new-video-poster.jpeg';
import { SvgIcons } from 'assets/images/SvgIcons';
import Button from 'components/button';
import Title from 'components/title';
import VideoPlayer from 'components/videoPlayer';
import { any, bool, func, string } from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { generateUuid } from 'utils/generateUuid';

const VideoWithImgPreview = forwardRef(
  (
    {
      src,
      srcType,
      videoName,
      youTubeLink,
      onPlayVideo,
      className = '',
      redirectLink = '',
      id = '',
      isAutomaticallyControl = false,
      playingVideoId = '',
      description = '',
      fileLink
    },
    ref
  ) => {
    const videoPlayerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
      if (isAutomaticallyControl && playingVideoId) {
        if (id === playingVideoId) {
          if (videoPlayerRef?.current) {
            setIsPlaying(true);

            videoPlayerRef.current.play();
          } else {
            setIsPlaying(true);
          }
        } else {
          if (videoPlayerRef?.current) {
            setIsPlaying(false);

            videoPlayerRef.current.pause();
          } else {
            setIsPlaying(false);
          }
        }
      }
    }, [isAutomaticallyControl, playingVideoId, id]);

    const handlePlayVideo = useCallback(() => {
      if (!isAutomaticallyControl) {
        if (!redirectLink) {
          if (videoPlayerRef?.current) {
            setIsPlaying(true);

            videoPlayerRef.current.play();
          } else {
            setIsPlaying(true);
          }
        } else {
          window.open(redirectLink, '_blank');
        }
      } else {
        if (!redirectLink) {
          if (onPlayVideo) onPlayVideo(id);
        } else {
          window.open(redirectLink, '_blank');
        }
      }
    }, [videoPlayerRef, redirectLink, onPlayVideo]);

    return (
      <div className='w-full'>
        {description ? <p className='text-center text-white mb-4'>{description}</p> : <></>}
        <div ref={ref} id={id} className={`w-full z-10 relative ${className}`}>
          <div className='w-full pb-1446-805 overflow-hidden relative'>
            {!isPlaying ? (
              <>
                <img src={ImgBg} alt={videoName} className='w-full h-full absolute inset-0 object-cover z-10' />
                <Title
                  titleTag='h6'
                  title={videoName}
                  titleClassName='!mb-0'
                  rootClassName='absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
                />
                <button
                  className='absolute z-30 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[#292A18] rounded-full border-4 border-[#292A18] flex justify-center items-center w-24 h-24 opacity-80 md:w-44 md:h-44'
                  onClick={handlePlayVideo}
                >
                  {React.cloneElement(SvgIcons.TrianglePolygon, { className: 'w-12 h-12 md:h-24 md:w-24' })}
                </button>
              </>
            ) : (
              <></>
            )}
            <VideoPlayer
              ref={videoPlayerRef}
              src={src}
              srcType={srcType}
              autoPlay={false}
              muted={false}
              controls
              youTubeLink={youTubeLink}
              playing={isPlaying}
              style={{
                position: 'absolute',
                left: 0,
                top: 0
              }}
              className='w-full h-full absolute inset-0'
            />
          </div>
        </div>
        {fileLink ? (
          fileLink ? (
            typeof fileLink === 'string' ? (
              <div className='w-full mt-6 flex justify-center items-center'>
                <Button onClick={() => window.open(fileLink, '_blank')} className='!border-primary w-full max-w-[200px] sm:w-fit'>
                  Download File
                </Button>
              </div>
            ) : (
              <div className='w-full flex flex-col justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row'>
                {fileLink.map((link, index) => (
                  <div key={generateUuid()} className='w-full mt-6 flex justify-center items-center'>
                    <Button onClick={() => window.open(link, '_blank')} className='!border-primary w-full max-w-[200px] sm:w-fit'>
                      Download File {index + 1}
                    </Button>
                  </div>
                ))}
              </div>
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
);

VideoWithImgPreview.displayName = 'VideoWithImgPreview';

VideoWithImgPreview.propTypes = {
  src: any,
  srcType: any,
  videoName: any,
  youTubeLink: any,
  className: string,
  redirectLink: string,
  id: string,
  isAutomaticallyControl: bool,
  playingVideoId: string,
  onPlayVideo: func,
  description: string,
  fileLink: string
};

export default VideoWithImgPreview;
