import React, { useMemo } from 'react';
import { CookieConsent } from 'react-cookie-consent';

const CookieConsentComp = () => {
  const buttonClassName = useMemo(() => {
    return '!bg-primary !text-white !shadow-black-65 !rounded-full !py-2 !px-4 hover:!text-black hover:!bg-primary/50';
  }, []);

  return (
    <CookieConsent
      containerClasses='w-full font-sans !bg-white xl:rounded-3xl xl:py-3 xl:px-4 xl:right-4 xl:!bottom-4 xl:!left-[unset] xl:max-w-xl'
      buttonText='Allow all cookies'
      enableDeclineButton
      declineButtonText='Use necessary cookies only'
      buttonWrapperClasses='mx-auto flex flex-col justify-center items-center md:my-auto md:flex-row'
      buttonClasses={buttonClassName}
      declineButtonClasses={buttonClassName}
    >
      <h6 className='font-lemon-milk text-black'>This website uses cookies</h6>
      <p className='w-full text-black mt-4'>
        We use cookies to personalize content and ads, provide social media features, and to analyze our traffic. We also share information about your
        use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to
        them or that they’ve collected from your use of their services.
      </p>
    </CookieConsent>
  );
};

export default CookieConsentComp;
