import Footer from 'components/footer';
import Header from 'components/header';
import PageContainer from 'components/pageContainer';
import Title from 'components/title';
import SocialAndScrollTop from 'pages/client/homePage/SocialAndScrollTop';
import { tradingSteps } from 'pages/tradingPage/configs';
import TradingSteps from 'pages/tradingPage/TradingSteps';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { layoutSelector } from 'stores/layout/selector';

const TradingPage = () => {
  const { pathname } = useLocation();
  const { headerHeight } = useSelector(layoutSelector);

  return (
    <div className='min-h-screen w-full flex flex-col relative overflow-x-hidden'>
      <Header />
      <div className='min-h-screen w-full flex flex-col relative' style={{ padding: `${headerHeight}px 0` }}>
        <PageContainer>
          <Title title={tradingSteps[pathname].title} />
          <TradingSteps />
          <div className='w-full max-w-5xl space-y-16 relative lg:space-y-32'>
            <Outlet />
          </div>
          <SocialAndScrollTop />
        </PageContainer>
      </div>
      <Footer />
    </div>
  );
};

export default TradingPage;
