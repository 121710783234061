import LogoImage from 'assets/images/logo-white.svg';
import Container from 'components/container';
import CountUpNumber from 'components/countUpNumber';
import { useIsInViewport } from 'hooks/index';
import { theKingdomData } from 'pages/client/homePage/configs';
import React, { useLayoutEffect, useRef } from 'react';

const DataCountSection = () => {
  const sectionRef = useRef(null);
  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const isOnScreen = useIsInViewport(sectionRef);

  useLayoutEffect(() => {
    if (sectionRef?.current && svgRef?.current) {
      // INFO: set width for svg
      const handleSetWidthForSvg = () => {
        const sectionCurrent = sectionRef.current;
        const svgCurrent = svgRef.current;

        svgCurrent.setAttribute('width', sectionCurrent.offsetWidth / 2);
      };

      document.addEventListener('resize', handleSetWidthForSvg);

      handleSetWidthForSvg();

      return () => document.removeEventListener('resize', handleSetWidthForSvg);
    }
  }, [sectionRef, svgRef]);

  useLayoutEffect(() => {
    if (svgRef?.current && pathRef?.current) {
      const pathCurrent = pathRef.current;
      const pathLength = pathCurrent?.getTotalLength();

      pathCurrent.style.strokeDasharray = pathLength + ' ' + pathLength;

      pathCurrent.style.strokeDashoffset = pathLength;

      window.addEventListener('scroll', () => {
        if (isOnScreen) {
          const scrollPercentage =
            (document.documentElement.scrollTop + document.body.scrollTop) /
            (document.documentElement.scrollHeight - document.documentElement.clientHeight);

          const drawLineHeight = pathLength * scrollPercentage;
          const calculateOffset = pathLength - drawLineHeight * 4.35;
          if (calculateOffset > -(pathLength * 2)) {
            pathCurrent.style.strokeDashoffset = calculateOffset;
          } else {
            pathCurrent.style.strokeDashoffset = -pathLength * 2;
          }
        }
      });
    }
  }, [svgRef, pathRef, isOnScreen]);

  return (
    <section ref={sectionRef} className='w-full relative py-10 xl:h-screen'>
      <Container className='h-full flex flex-col justify-center items-center space-y-10 xl:space-y-20'>
        <div className='line-container line-container__5'>
          <svg
            ref={svgRef}
            width='962'
            height='329'
            viewBox='0 0 962 329'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='xMidYMax meet'
          >
            <path ref={pathRef} d='M959.5 2L728.5 231H3V329' stroke='url(#paint0_linear_24_5)' strokeWidth='5' />
            <defs>
              <linearGradient id='paint0_linear_24_5' x1='-5257.75' y1='94.6048' x2='-4972.48' y2='-1614.23' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#988313' stopOpacity='0' />
                <stop offset='0.510417' stopColor='#988313' />
                <stop offset='1' stopColor='#988313' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className='w-full flex justify-center items-center'>
          <div className='w-full max-w-[100px] sm:max-w-[150px]'>
            <div className='w-full relative pb-100'>
              <img src={LogoImage} alt='The Kingdom' className='absolute w-full h-full inset-0 object-cover' />
            </div>
          </div>
          <h6 className='flex flex-col justify-center items-start text-white-85 text-2xl sm:text-4xl'>
            <span className='drop-shadow-black-85'>we are</span>
            <span className='drop-shadow-black-85 whitespace-nowrap'>The Kingdom</span>
          </h6>
        </div>

        <div className='w-full grid grid-cols-1 gap-10 sm:grid-cols-2 md:gap-14 xl:gap-0 xl:flex xl:justify-between xl:items-center'>
          {theKingdomData.map((data) => {
            const step = data.number >= 100000 ? 1000 : data.number >= 1000 ? 10 : 1;

            return (
              <div key={data.label} className='text-white-85 text-center space-y-4'>
                <p className='text-4xl md:text-5xl lg:text-6xl'>
                  <CountUpNumber endNumber={data.number} duration={1} step={step} decimal={data.isHasSeparatorDot} />
                  {data.isHasPlusIcon ? '+' : ''}
                </p>
                <span className='block text-xl md:text-32px'>{data.label}</span>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default DataCountSection;
