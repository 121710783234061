import axios from 'axios';
import { LocalStorageName } from 'constants/index';

const axiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const access = JSON.parse(window.localStorage.getItem(LocalStorageName.ACCESS_TOKEN_NAME));

    if (access) {
      const expireDate = new Date(access.expire);
      const currentDate = new Date();

      if (currentDate > expireDate) {
        window.localStorage.removeItem('access');
      }

      config.headers.accessToken = access.token.toString();
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) window.localStorage.removeItem(LocalStorageName.ACCESS_TOKEN_NAME);

    if (response.status === 201 || response.status === 200) return response.data;
  },
  (error) => {
    const errorMessage = typeof error?.response?.data === 'string' ? error?.response?.data : error?.response?.data?.errors?.[0]?.msg;

    return Promise.reject(errorMessage);
  }
);

export default axiosInstance;
