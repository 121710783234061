import Button from 'components/button';
import { tradingSteps } from 'pages/tradingPage/configs';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { checkRequirePage } from 'utils/checkRequirePage';

const TradingSteps = () => {
  const { pathname } = useLocation();
  const navigate = useCallback((key) => (window.location.href = key), []);

  console.log(pathname);

  return (
    <div className='w-full flex justify-center items-center flex-wrap gap-3 xl:gap-14'>
      {Object.keys(tradingSteps).map((key) => (
        <Button
          key={key}
          variant='tertiary'
          className={`!p-3 sm:!py-3.5 sm:!px-6 ${pathname === key ? '' : 'border-none'} ${
            checkRequirePage(tradingSteps[key].id) ? '!border-solid border-primary/80 bg-primary/60' : ''
          }`}
          onClick={() => navigate(key)}
        >
          {tradingSteps[key].name}
        </Button>
      ))}
    </div>
  );
};

export default TradingSteps;
