import Logo from 'assets/images/logo-colorful.png';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import LoginForm from 'pages/authenticatePage/loginPage/LoginForm';
import { useCloseAuthenticatePage } from 'pages/authenticatePage/useCloseAuthenticatePage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const handleClosePopup = useCloseAuthenticatePage();

  return (
    <ModalComp isOpen={true} onClose={handleClosePopup}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
          <p className='w-full text-center'>
            Noch keinen Account?{' '}
            <span className='link-style' onClick={() => navigate(RouteBasePaths.RegisterPage)}>
              Jetzt Account erstellen.
            </span>
          </p>
        </div>
        <LoginForm />
      </div>
    </ModalComp>
  );
};

export default LoginPage;
