import './ImageTextListingDiagram.scss';

import ImageTextItem from 'components/imageTextListingDiagram/ImageTextItem';
import { arrayOf, exact, oneOf, string } from 'prop-types';
import React from 'react';
import { generateUuid } from 'utils/generateUuid';

const ImageTextListingDiagram = ({ data }) => {
  return (
    <div className='container mx-auto image-text-listing-diagram'>
      {data.map((item, index) => (
        <ImageTextItem key={generateUuid()} itemIndex={index} data={item} totalRecord={data.length} />
      ))}
    </div>
  );
};

ImageTextListingDiagram.propTypes = {
  data: arrayOf(
    exact({
      fileId: string,
      name: string,
      position: string,
      description: string,
      social: arrayOf(
        exact({
          type: oneOf(['instagram', 'youtube']),
          link: string
        })
      )
    })
  )
};

export default ImageTextListingDiagram;
