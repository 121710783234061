export const theKingdomData = [
  {
    label: 'founded',
    number: 2013,
    isHasPlusIcon: false,
    isHasSeparatorDot: false
  },
  {
    label: 'country',
    number: 140,
    isHasPlusIcon: true,
    isHasSeparatorDot: false
  },
  {
    label: 'languages',
    number: 20,
    isHasPlusIcon: true,
    isHasSeparatorDot: false
  },
  {
    label: 'members',
    number: 400000,
    isHasPlusIcon: true,
    isHasSeparatorDot: true
  }
];

export const leaders = [
  {
    title: 'Müslüm Demir',
    subtitle: 'Director',
    fileId: '1aGiGYXIDCyHpX_PsGZg4ilI9HV-aa4zw'
  },
  {
    title: 'Muhammed Ali Kaplan',
    subtitle: 'E5000',
    fileId: '1u91qmAS2MUFlzfalfm2thqg15LZo25PM'
  },
  {
    title: 'Joshua Badder',
    subtitle: 'E5000',
    fileId: '1whPmsZsM7Br_76c_0cp7MryDxh6LjhHY'
  },

  {
    title: 'Ali Narin',
    subtitle: 'E2000',
    fileId: '19SSGI72d7rMgNjr7cxS3z6RnawhSN0Te'
  },
  // {
  //   title: 'Kaan Pekcan',
  //   subtitle: 'E2000',
  //   fileId: '1AJGYTtiOQlb8A1VSbD2YBgsR9jyFv1Ja'
  // },
  {
    title: 'Alessio Burgio',
    subtitle: 'E2000',
    fileId: '1S_tAKeNlLSveShLShz9dXMmkPkTYnME-'
  },
  {
    title: 'Julia Marie',
    subtitle: 'E2000',
    fileId: '1MtuaH5tkYxctWjxkRdNyK5vPETa70ues'
  },
  {
    title: 'Adem Calik',
    subtitle: 'E2000',
    fileId: '1ojaZRqCx-g3mcPGCXVjoW10eFZdPVSN7'
  },
  {
    title: 'Ben Pötzsch',
    subtitle: 'E2000',
    fileId: '1i4sIBtaZzFnOoxsLEVEmuo3Y4Yn79QcT'
  },
  {
    title: 'Nicole Krizkova',
    subtitle: 'E2000',
    fileId: '1nUUzyTVhVVXYUQJN_YKyamOp8V-m4u5T'
  },
  // {
  //   title: 'Marco Hechler',
  //   subtitle: 'E1000',
  //   fileId: '1mY6OI0bgwf0yFTddb0PWQK9dJuY5P8RR'
  // },
  {
    title: 'Yasemin Demir',
    subtitle: 'E2000',
    fileId: '1pRmXGtNX4nKhfBdxnuGbKFHcCSI_VRcz'
  },
  {
    title: 'Yagmur Dumanli',
    subtitle: 'E2000',
    fileId: '1DSTE59Z0eyDlfz9ri3Dq8S4cfXyMR2vI'
  },
  {
    title: 'Yavuz Cakal',
    subtitle: 'E2000',
    fileId: '1RudKN9H8dtimkqXUzMWoVfxBhNVn7Mqm'
  },
  {
    title: 'Cimberly Visconti Di Verona',
    subtitle: 'E1000',
    fileId: '1uQcdW77HwNv4nWbtPfR3Utr8PMDDUXBp'
  },
  {
    title: 'Fabion Morina',
    subtitle: 'E1000',
    fileId: '1Yt1i-XY93wFWMQUKZwlD0QGgKGnK4aKj'
  },
  // {
  //   title: 'Katharina Gramling',
  //   subtitle: 'E1000',
  //   fileId: '1TolRmIH80kk5IMTpi_2g3HKkizUhHbiH'
  // },
  {
    title: 'Batuhan Özden',
    subtitle: 'E1000',
    fileId: '1CV-S5SXcNyaJTiqhv6Wg7zxcNBhpZolo'
  },
  {
    title: 'Julian Jejakody',
    subtitle: 'E1000',
    fileId: '1VbeADqiFwwqnu4jFPKKcWmzInibz76vr'
  },
  {
    title: 'Jean-Marc Kapela',
    subtitle: 'E1000',
    fileId: '1voMjaD2sxWmvs49tCv7p86SkC-tthgLn'
  },
  {
    title: 'Calogero Casa',
    subtitle: 'E1000',
    fileId: '17e_gDaQQCQadEolXBEkyKF-R4H8iQirw'
  },
  // {
  //   title: 'Niko Schmidt',
  //   subtitle: 'E1000',
  //   fileId: '131CQerDN7xRHsWbNdpf6i5HeaoSiFn7v'
  // },
  // {
  //   title: 'Patrick Hechler',
  //   subtitle: 'E1000',
  //   fileId: '19XPBUFONfUagH39Z6lMPxkKwIVc-yzV6'
  // },
  {
    title: 'Jonas Yunus Grimmelsmann',
    subtitle: 'E1000',
    fileId: '1aqtUZFHesp1gvK9IlgeQ2PXyraGAo-Fp'
  },
  {
    title: 'Katrin Griesbauer',
    subtitle: 'E1000',
    fileId: '1Hh2b2XCsGsTEen8fN-cGTJIqIoMnzby9'
  },
  {
    title: 'Nicole Waser',
    subtitle: 'E1000',
    fileId: '1NsgTbbRciXRdaDZjgdYTTGzOxZs7VR8B'
  },
  // {
  //   title: 'Kristina Schmidt',
  //   subtitle: 'E1000',
  //   fileId: '1DkZv0YR2mnk3Jbgx39Cox5uM3vHRdeRp'
  // },
  {
    title: 'Dilara Balik',
    subtitle: 'E1000',
    fileId: '1iA6W7VgpVMXyTYpqDa_QlI9a1BBEIx9Z'
  },
  {
    title: 'Miralem Nakicevic',
    subtitle: 'E1000',
    fileId: '1JAnnhECZomq-yu7aRSkI05ePaVPDwkL5'
  },
  {
    title: 'Assvan Mohanaraajah',
    subtitle: 'E1000',
    fileId: '1e4XzCmQgSumAhkZtqap6EDtYTEncackF'
  },
  {
    title: 'Joel Matteo Jiménez Rodriquens',
    subtitle: 'E1000',
    fileId: '1zKyIUJX-1q1xdtd7NaoWzfhfuI0oeELn'
  },
  {
    title: 'Chris Krakl',
    subtitle: 'E1000',
    fileId: '1ohTTSMMg80w_0rptiQBuUZ1OPtqC6lkw'
  },
  {
    title: 'Selina Özdemir',
    subtitle: 'E1000',
    fileId: '1UTrOkXlM_0wrQOYAvf98BxFo2fpDzsio'
  }
];
