import Footer from 'components/footer';
import Header from 'components/header';
import SocialAndScrollTop from 'pages/client/homePage/SocialAndScrollTop';
import React from 'react';
import { Outlet } from 'react-router-dom';

const LoggedPageLayout = () => {
  return (
    <div className='min-h-screen w-full flex flex-col overflow-hidden relative page-background'>
      <Header />
      {/* <div className='w-full h-full absolute inset-0'>*/}
      {/*  <img src={BgImage} alt='The Kingdom' className='w-full h-full inset-0 object-cover' />*/}
      {/* </div>*/}
      <Outlet />
      <Footer />
      <SocialAndScrollTop />
    </div>
  );
};

export default LoggedPageLayout;
