import DividerAnimation from 'components/dividerAnimation';
import PageContainer from 'components/pageContainer';
import Title from 'components/title';
import RouteBasePaths from 'constants/RouteBasePaths';
import GetPaidSessions from 'pages/loggedPage/informationPage/GetPaidSessions';
import { teamCalls, videosData } from 'pages/loggedPage/whatWaitForYouPage/configs';
import TradingPageFooter from 'pages/tradingPage/TradingPageFooter';
import VideoWithImgPreview from 'pages/tradingPage/VideoWithImgPreview';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'stores/layout/selector';
import { generateUuid } from 'utils/generateUuid';

const LoggedWhatWaitForYouPage = () => {
  const { headerHeight } = useSelector(layoutSelector);
  const [playingVideoId, setPlayingVideoId] = useState('');
  const handleControlVideoPlayer = (videoIdPlaying) => setPlayingVideoId(videoIdPlaying);

  return (
    <div className='min-h-screen w-full flex flex-col relative' style={{ padding: `${headerHeight}px 0` }}>
      <PageContainer>
        <Title title='Was dich erwartet' />
        {videosData.map((video, index) => (
          <VideoWithImgPreview
            id={`video-player-${index}`}
            key={generateUuid()}
            youTubeLink={video.src}
            srcType={video.type}
            videoName={video.name}
            isAutomaticallyControl={true}
            playingVideoId={playingVideoId}
            redirectLink={video.redirectLink}
            onPlayVideo={handleControlVideoPlayer}
          />
        ))}
        <DividerAnimation className='relative w-full' isFullWidth={false} />
        <GetPaidSessions title='Wöchentlicher Teamcall' data={teamCalls} />
        <TradingPageFooter nextStepLabel='Step 1' nextStepPath={RouteBasePaths.LoggedTradingPage} />
      </PageContainer>
    </div>
  );
};

export default LoggedWhatWaitForYouPage;
