import RouteBasePaths from 'constants/RouteBasePaths';

export const footerLinks = [
  {
    label: 'Mitglied werden',
    link: RouteBasePaths.BecomeAMember
  },
  {
    label: 'datenschutz',
    link: RouteBasePaths.DataProtectionPage
  },
  {
    label: 'Impressum',
    link: RouteBasePaths.ImpressumPage
  }
];
