import CalendarBlackIcon from 'assets/images/calendar-black-icon.png';
import CalendarIcon from 'assets/images/calendar-icon.png';
import { any, bool, func, string } from 'prop-types';
import React, { forwardRef } from 'react';

const DatePickerInput = forwardRef(({ isCalendarBlack = false, ...props }, ref) => {
  return (
    <div ref={ref} onClick={props.onClick} className='relative'>
      <input
        className='px-4 py-2 w-full bg-transparent outline-none border-2 border-white rounded-full text-2xl text-center shadow-md sm:text-32px'
        value={props.value}
        readOnly
        placeholder={props.placeholder}
      />
      {!isCalendarBlack ? (
        <img src={CalendarIcon} alt='calendar icon' onClick={props.onClick} className='cursor-pointer absolute right-10 top-1/2 -translate-y-1/2' />
      ) : (
        <img
          src={CalendarBlackIcon}
          alt='calendar icon'
          onClick={props.onClick}
          className='cursor-pointer absolute right-10 top-1/2 -translate-y-1/2'
        />
      )}
    </div>
  );
});

DatePickerInput.displayName = 'DatePickerInput';

DatePickerInput.propTypes = {
  onClick: func,
  value: any,
  placeholder: string,
  isCalendarBlack: bool
};

export default DatePickerInput;
