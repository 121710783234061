import Title from 'components/title';
import { any, string } from 'prop-types';
import React from 'react';
import { generateUuid } from 'utils/generateUuid';

const GetPaidSessions = ({ data, title = 'Get paid sessions' }) => {
  return (
    <section className='w-full space-y-5 sm:space-y-10 md:space-y-20'>
      <Title subtitle={title} />
      <div className='flex justify-center items-center flex-col gap-6 xl:flex-row xl:flex-wrap xl:items-stretch xl:gap-11'>
        {data.map((session) => (
          <div
            key={generateUuid()}
            className='max-w-md w-full border border-primary rounded-3xl uppercase text-white text-center space-y-2 text-xl py-5 px-4 lg:px-8 lg:text-2xl xl:basis-1/2'
          >
            <p className='text-primary'>{session.title}</p>
            <div className='w-full space-y-2'>
              {session.dateTimes.map((date) => (
                <p key={generateUuid()}>{date}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

GetPaidSessions.propTypes = {
  data: any,
  title: string
};

export default GetPaidSessions;
