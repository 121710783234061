import { LocalStorageName } from 'constants/index';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useVerifyEmail = () => {
  const { token } = useParams();
  const userId = window.localStorage.getItem(LocalStorageName.USER_ID_NAME);

  return useCallback(async () => {
    return await axiosInstance.patch(`${AUTHENTICATE_BASE_URL}/verify-email`, {
      userId: userId ? JSON.parse(userId) : '',
      token: token
    });
  }, [token, userId]);
};

export default useVerifyEmail;
