import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToastifyProvider = () => {
  return <ToastContainer autoClose={2000} position='bottom-right' />;
};

export default ToastifyProvider;
