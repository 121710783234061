import { LocalStorageName } from 'constants/index';
import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import { useMutateGetAuthCase } from 'services/authenticate/useGetAuth';
import axiosInstance from 'services/utils/axiosInstance';

const useLogin = () => {
  const mutateCase = useMutateGetAuthCase();

  return useCallback(
    async (loginData) => {
      const response = await axiosInstance.post(`${AUTHENTICATE_BASE_URL}/login`, {
        email: loginData.email,
        password: loginData.password
      });
      if (response) {
        window.localStorage.setItem(LocalStorageName.ACCESS_TOKEN_NAME, JSON.stringify(response.accessToken));
      }

      mutateCase();

      return response;
    },
    [mutateCase]
  );
};

export default useLogin;
