import { arrayOf, element, oneOfType } from 'prop-types';
import { createContext } from 'react';
import { useGetAuth } from 'services/authenticate';

const initialState = {
  accountInfo: null,
  isLoading: false
};

export const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const { accountInfo, isLoading } = useGetAuth();

  return <AuthContext.Provider value={{ accountInfo: accountInfo, isLoading: isLoading }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: oneOfType([element, arrayOf(element)])
};

export default AuthProvider;
