import { SvgIcons } from 'assets/images/SvgIcons';

export const socialDetails = [
  // {
  //   icon: SvgIcons.Instagram,
  //   title: 'Instagram',
  //   url: ''
  // },
  {
    icon: SvgIcons.Youtube,
    title: 'Youtube',
    url: 'https://www.youtube.com/channel/UCGt6ByaIq7GppQX4D8pMKNA'
  }
];
