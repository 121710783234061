import Button from 'components/button';
import { string } from 'prop-types';
import React, { useCallback } from 'react';

const TradingPageFooter = ({ nextStepLabel, nextStepPath }) => {
  const handleNavigate = useCallback(() => (window.location.href = nextStepPath), [nextStepPath]);

  return (
    <div className='w-full space-y-16 lg:space-y-32'>
      {/* <p className='text-2xl text-white text-center'>Die wichtigsten calls, termine und links findest du unter informationen</p>*/}
      <div className='flex justify-center items-center flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-14'>
        {/* <Button variant='secondary' className='w-full sm:max-w-[200px] sm:w-fit' onClick={() => navigate(RouteBasePaths.LoggedInfosPage)}>*/}
        {/*  Informationen*/}
        {/* </Button>*/}
        {nextStepLabel && nextStepPath ? (
          <Button variant='secondary' className='w-full sm:w-fit' onClick={handleNavigate}>
            {nextStepLabel}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

TradingPageFooter.propTypes = {
  nextStepLabel: string,
  nextStepPath: string
};

export default TradingPageFooter;
