import Loading from 'components/loading';
import { routes } from 'components/mainLayout/routes';
import { LocalStorageName, RouteBasePath } from 'constants/index';
import { useAuth } from 'hooks/index';
import { useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

const MainLayout = () => {
  const { pathname } = useLocation();
  const { accountInfo, isLoading } = useAuth();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  const handleGenerateElement = (element, path, roles, requiredAccessPage) => {
    const accessToken = window.localStorage.getItem(LocalStorageName.ACCESS_TOKEN_NAME);
    if (path === RouteBasePath.AuthenticatePage && accessToken && accountInfo) return <Navigate to={RouteBasePath.HomePage} />;
    if (roles?.length) {
      if (requiredAccessPage) {
        if (accountInfo) {
          return roles.includes(accountInfo.role) && accountInfo.isVerifiedByAdmin ? (
            element
          ) : (
            <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />
          );
        } else {
          return <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />;
        }
      } else {
        if (accountInfo) {
          return roles.includes(accountInfo.role) ? element : <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />;
        } else {
          return <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />;
        }
      }
    }
    if (requiredAccessPage) {
      if (accountInfo) {
        return accountInfo.isVerifiedByAdmin ? element : <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />;
      } else {
        return <Navigate to={RouteBasePath.AuthenticatePage} state={{ from: pathname }} />;
      }
    }

    return element;
  };

  return !isLoading ? (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={`parent-key-${index}`}
          path={route.path}
          element={handleGenerateElement(route.element, route.path, route.roles, route.requiredAccessPage)}
        >
          {route.children.length ? (
            route.children.map((children, index) => (
              <Route
                key={`children-key-${index}`}
                path={children.path}
                element={handleGenerateElement(children.element, children.path, children.roles, children.requiredAccessPage)}
                index={children.isIndex}
              />
            ))
          ) : (
            <></>
          )}
        </Route>
      ))}
    </Routes>
  ) : (
    <Loading isPageLoading />
  );
};

export default MainLayout;
