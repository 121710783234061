import Container from 'components/container';
import Title from 'components/title';
import React from 'react';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'stores/layout/selector';

const ImpressumPage = () => {
  const { headerHeight } = useSelector(layoutSelector);

  return (
    <div className='min-h-screen w-full flex flex-col' style={{ padding: `${headerHeight}px 0` }}>
      <Container className='h-full flex flex-col justify-center items-center space-y-16 my-auto relative lg:space-y-32'>
        <div className='w-full space-y-10 lg:space-y-20'>
          <Title title='Impressum' titleClassName='!text-left' />
        </div>
        <div className='w-full space-y-4 font-sans text-white-85 normal-case' style={{ wordBreak: 'break-word' }}>
          <article className='w-full text-lg'>
            <p>TheKingdom</p>
            <p>Friedrich Ebert Straße 33</p>
            <p>04600 Altenburg</p>
            <p>Deutschland</p>
          </article>
          <article className='w-full space-y-4 text-lg'>
            <p>
              Mail:{' '}
              <a href='mailto:theimkingdom@gmail.com' className='lowercase underline link-style'>
                theimkingdom@gmail.com
              </a>
            </p>
            <p>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden{' '}
              <a href='https://ec.europa.eu/consumers/odr/ [https://ec.europa.eu/consumers/odr/' className='link-style'>
                https://ec.europa.eu/consumers/odr/
              </a>
              . Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
            </p>
            <p>
              Impressum [
              <a href='https://legal.trustedshops.com/produkte/rechtstexter' className='link-style'>
                https://legal.trustedshops.com/produkte/rechtstexter
              </a>
              ] erstellt mit dem Trusted Shops [
              <a href='https://legal.trustedshops.com/' className='link-style'>
                https://legal.trustedshops.com/
              </a>
              ] Rechtstexter in Kooperation mit FÖHLISCH Rechtsanwälte [
              <a href='https://foehlisch.com' className='link-style'>
                https://foehlisch.com
              </a>
              ].
            </p>
          </article>
        </div>
      </Container>
    </div>
  );
};

export default ImpressumPage;
