import './Checkbox.scss';

import { arrayOf, element, oneOfType, string } from 'prop-types';
import React from 'react';

const Checkbox = ({ label, labelClassName = '', inputClassName = '', ...props }) => {
  return (
    <label className={`checkbox-container ${labelClassName}`}>
      {label}
      <input {...props} type='checkbox' className={`checkbox-input ${inputClassName}`} />
      <span className='checkbox-checkmark'></span>
    </label>
  );
};

Checkbox.propTypes = {
  label: oneOfType([string, element, arrayOf(element)]),
  inputClassName: string,
  labelClassName: string
};

export default Checkbox;
