import { YOU_TUBE_VIDEO_TYPE } from 'components/videoPlayer';

export const videosData = [
  {
    name: 'Was dich erwartet',
    src: ' https://youtu.be/o811Wt6Hln8?si=HsWuBGpQZBv7jSzi',
    type: YOU_TUBE_VIDEO_TYPE,
    redirectLink: ''
  }
];

export const teamCalls = [
  {
    title: 'The Kingdom Teamcall',
    dateTimes: ['Dienstag 21 Uhr']
  },
  {
    title: 'The Kingdom E700 Call',
    dateTimes: ['Mittwoch 21 Uhr']
  }
];
