import Loading from 'components/loading';
import { LocalStorageName } from 'constants/index';
import RouteBasePaths from 'constants/RouteBasePaths';
import { useEffectOnce } from 'hooks/index';
import VerifyFailedModal from 'pages/client/verifyEmailPage/VerifyFailedModal';
import VerifySuccessModal from 'pages/client/verifyEmailPage/VerifySuccessModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVerifyEmail } from 'services/authenticate';

const VERIFY_EMAIL_SUCCESS = 'success';
const VERIFY_EMAIL_FAILURE = 'fail';

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const verifyEmailService = useVerifyEmail();
  const [isLoading, setIsLoading] = useState(true);
  const [verifyEmailStatus, setVerifyEmailStatus] = useState('');

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await verifyEmailService();

        if (response) {
          // remove userId temp in localstorage
          window.localStorage.removeItem(LocalStorageName.USER_ID_NAME);

          setVerifyEmailStatus(VERIFY_EMAIL_SUCCESS);
        }
      } catch (error) {
        setVerifyEmailStatus(VERIFY_EMAIL_FAILURE);
      } finally {
        setIsLoading(false);
      }
    })();
  });

  const handleCloseModal = () => {
    navigate(RouteBasePaths.HomePage);

    setVerifyEmailStatus('');
  };

  return (
    <>
      <VerifySuccessModal isOpen={verifyEmailStatus === VERIFY_EMAIL_SUCCESS} onClose={handleCloseModal} />
      <VerifyFailedModal isOpen={verifyEmailStatus === VERIFY_EMAIL_FAILURE} onClose={handleCloseModal} />
      {isLoading ? <Loading isLoadingMask /> : <></>}
    </>
  );
};

export default VerifyEmailPage;
