import Button from 'components/button';
import Input from 'components/input';
import Loading from 'components/loading';
import RouteBasePaths from 'constants/RouteBasePaths';
import { useFormik } from 'formik';
import AuthWithGoogleButton from 'pages/authenticatePage/authenticate/authWithGoogleButton';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLogin } from 'services/authenticate';
import * as Yup from 'yup';

const LoginForm = () => {
  const initialValues = {
    email: '',
    password: ''
  };
  const navigate = useNavigate();
  const loginService = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter a valid email').trim().required('Please enter your email'),
      password: Yup.string().trim().required('Please enter your password')
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const loginData = {
          email: values.email,
          password: values.password
        };
        const response = await loginService(loginData);
        if (response) {
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate(RouteBasePaths.LoggedTradingPage);
          }

          toast.success('Login successful');
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[508px] mx-auto form-group'>
        <div className='form-item'>
          <label htmlFor='email'>Deine E-Mail-Adresse</label>
          <Input type='email' id='email' name='email' value={formik.values.email} onChange={formik.handleChange} />
          {formik.touched.email && formik.errors.email ? <span className='form-error'>{formik.errors.email}</span> : <></>}
        </div>

        <div className='form-item'>
          <div className='w-full flex justify-between items-center'>
            <label htmlFor='password'>Passwort</label>
            <p className='link-style' onClick={() => navigate(RouteBasePaths.ResetPasswordPage)}>
              Passwort vergessen?
            </p>
          </div>
          <Input type='password' id='password' name='password' value={formik.values.password} onChange={formik.handleChange} />
          {formik.touched.password && formik.errors.password ? <span className='form-error'>{formik.errors.password}</span> : <></>}
        </div>

        <div className='w-full flex justify-center items-center flex-col space-y-4 !mt-11 sm:space-y-0 sm:flex-row sm:space-x-9'>
          <Button type='submit' className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit'>
            Login
          </Button>
          <AuthWithGoogleButton label='Sign in with Google' />
        </div>
      </form>
      {isLoading ? <Loading isLoadingMask /> : <></>}
    </>
  );
};

export default LoginForm;
