import Container from 'components/container';
import { any } from 'prop-types';
import React, { forwardRef, useLayoutEffect, useRef } from 'react';

const CircleContent = forwardRef(({ setCircleRef }, ref) => {
  const lineSvgRef = useRef(null);
  const linePathRef = useRef(null);
  const circleRef = useRef(null);
  const circleSvgRef = useRef(null);
  const circlePathRef = useRef(null);

  useLayoutEffect(() => {
    setCircleRef(circleRef);
  }, [circleRef]);

  useLayoutEffect(() => {
    const handleSetLineStyle = () => {
      if (circleRef?.current && circleSvgRef?.current) {
        const circleCurrent = circleRef.current;
        const circleSvgCurrent = circleSvgRef.current;

        circleSvgCurrent.setAttribute('width', circleCurrent.offsetWidth);

        circleSvgCurrent.setAttribute('height', circleCurrent.offsetWidth);
      }
    };

    document.addEventListener('resize', handleSetLineStyle);

    handleSetLineStyle();

    return () => document.removeEventListener('resize', handleSetLineStyle);
  }, [circleRef, circleSvgRef]);

  useLayoutEffect(() => {
    if (circlePathRef?.current && circleSvgRef?.current) {
      const circlePathCurrent = circlePathRef.current;
      const circleSvgCurrent = circleSvgRef.current;

      const circlePathLength = circlePathCurrent?.getTotalLength();

      const circleSvgTop = circleSvgCurrent.getBoundingClientRect().top;

      circlePathCurrent.style.strokeDasharray = circlePathLength + ' ' + circlePathLength;

      circlePathCurrent.style.strokeDashoffset = circlePathLength;

      const circleSvgScrollPercent = circleSvgTop / document.documentElement.scrollHeight - 0.05;

      window.addEventListener('scroll', () => {
        // INFO: what % down is it?
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const multipleNumber = 7;
        if (scrollPercentage >= circleSvgScrollPercent) {
          const circleDrawLength = circlePathLength * (scrollPercentage - circleSvgScrollPercent);
          const calculateCircleOffset = circlePathLength - circleDrawLength * multipleNumber;

          if (calculateCircleOffset < circlePathLength) {
            circlePathCurrent.style.strokeDashoffset = calculateCircleOffset;
          } else {
            circlePathCurrent.style.strokeDashoffset = 0;
          }
        } else {
          circlePathCurrent.style.strokeDashoffset = circlePathLength;
        }
      });
    }
  }, [circlePathRef, circleSvgRef]);

  useLayoutEffect(() => {
    if (lineSvgRef?.current && linePathRef?.current) {
      const lineSvgCurrent = linePathRef.current;
      const linePathCurrent = linePathRef.current;

      const lineSvgTop = lineSvgCurrent.getBoundingClientRect().top;
      const pathLength = linePathCurrent?.getTotalLength();

      linePathCurrent.style.strokeDasharray = pathLength + ' ' + pathLength;

      linePathCurrent.style.strokeDashoffset = pathLength;
      const lineSvgScrollPercent = lineSvgTop / document.documentElement.scrollHeight;

      window.addEventListener('scroll', () => {
        // INFO: what % down is it?
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const multipleNumber = 6;
        if (scrollPercentage >= lineSvgScrollPercent) {
          const linePathDrawLength = pathLength * (scrollPercentage - lineSvgScrollPercent);
          const calculateLinePathOffset = pathLength - linePathDrawLength * multipleNumber;

          if (calculateLinePathOffset < pathLength) {
            linePathCurrent.style.strokeDashoffset = calculateLinePathOffset;
          } else {
            linePathCurrent.style.strokeDashoffset = 0;
          }
        } else {
          linePathCurrent.style.strokeDashoffset = pathLength;
        }
      });
    }
  }, [lineSvgRef, linePathRef]);

  return (
    <section ref={ref} className='w-full py-10 relative xl:h-screen'>
      <Container className='h-full relative flex'>
        <div ref={circleRef} className='m-auto w-full max-w-lg'>
          <div className='w-full relative pb-100'>
            <div className='line-container line-container__8'>
              <svg
                ref={circleSvgRef}
                width='891'
                height='891'
                viewBox='0 0 891 891'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='xMidYMax meet'
              >
                <circle
                  ref={circlePathRef}
                  cx='445.5'
                  cy='445.5'
                  r='440.5'
                  transform='rotate(-90 445.5 445.5)'
                  stroke='url(#paint0_radial_25_8)'
                  strokeOpacity='0.8'
                  strokeWidth='10'
                />
                <defs>
                  <radialGradient
                    id='paint0_radial_25_8'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientUnits='userSpaceOnUse'
                    gradientTransform='translate(446 445) rotate(90.0642) scale(446)'
                  >
                    <stop stopColor='#988313' />
                    <stop offset='0.977683' stopColor='#988313' stopOpacity='0' />
                    <stop offset='0.98881' stopColor='#988313' />
                    <stop offset='1' stopColor='#988313' stopOpacity='0' />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <h2 className='text-white-85 absolute w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center !leading-snug drop-shadow-black-85 px-9 text-xl sm:text-2xl lg:text-3xl'>
              BEHIND EVERY GREAT LEADER WAS A MENTOR WHO HELPED THEM STAY IN THE GAME.
            </h2>
          </div>
          <div className='line-container line-container__2'>
            <svg
              ref={lineSvgRef}
              width='963'
              height='633'
              viewBox='0 0 963 633'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              preserveAspectRatio='xMidYMax meet'
            >
              <path ref={linePathRef} d='M5 0V300L396.5 628H962.5' stroke='url(#paint0_linear_25_5)' strokeWidth='5' />
              <defs>
                <linearGradient id='paint0_linear_25_5' x1='-5261.25' y1='177.846' x2='-4281.64' y2='-2881.26' gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#988313' stopOpacity='0' />
                  <stop offset='0.510417' stopColor='#988313' />
                  <stop offset='1' stopColor='#988313' stopOpacity='0' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Container>
    </section>
  );
});

CircleContent.displayName = 'CircleContent';

CircleContent.propTypes = {
  setCircleRef: any
};

export default CircleContent;
