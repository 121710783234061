import { AccountStatus } from 'constants/index';
import _ from 'lodash';

export const generateUserDataObject = (data) => ({
  userId: _.get(data, 'userId', ''),
  email: _.get(data, 'email', ''),
  displayName: _.get(data, 'displayName', ''),
  firstName: _.get(data, 'firstName', ''),
  lastName: _.get(data, 'lastName', ''),
  dob: _.get(data, 'dob', ''),
  role: _.get(data, 'role', AccountStatus.USER_ROLE),
  status: _.get(data, 'status', AccountStatus.STATUS_NOT_ACTIVE),
  // isVerifyEmail: _.get(data, 'isVerifyEmail', AccountStatus.IS_NOT_VERIFIED_EMAIL),
  isVerifiedByAdmin: _.get(data, 'isVerifiedByAdmin', AccountStatus.IS_NOT_VERIFIED_BY_ADMIN),
  pagesAllowed: _.get(data, 'pagesAllowed', []),
  createdAt: _.get(data, 'createdAt', ''),
  updatedAt: _.get(data, 'updatedAt', '')
});
