import './DataProtectionPage.scss';

import Container from 'components/container';
import Title from 'components/title';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'stores/layout/selector';

const DataProtectionPage = () => {
  const { headerHeight } = useSelector(layoutSelector);

  return (
    <div className='min-h-screen w-full flex flex-col bg-no-repeat data-protection-page' style={{ padding: `${headerHeight}px 0` }}>
      <Container className='h-full flex flex-col justify-center items-center space-y-16 my-auto lg:space-y-32'>
        <div className='w-full space-y-10 lg:space-y-20'>
          <Title title='Datenschutz' titleClassName='!text-left' />
        </div>
        <div className='w-full space-y-4 font-sans text-white-85 normal-case data-protection-page__content-wrapper'>
          <article className='w-full text-lg'>
            <p>TheKingdom</p>
            <p>Friedrich Ebert Straße 33</p>
            <p>04600 Altenburg</p>
            <p>Deutschland</p>
          </article>
          <article className='data-protection-page__content-article'>
            <p>
              Mail:{' '}
              <a href='mailto:theimkingdom@gmail.com' className='lowercase underline link-style'>
                theimkingdom@gmail.com
              </a>
            </p>
            <p>
              Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren
              wir Sie ausführlich über den Umgang mit Ihren Daten.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>1. ZUGRIFFSDATEN UND HOSTING</p>
            <p>
              Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver
              lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des
              Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten
              werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots
              ausgewertet. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten
              Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Alle Zugriffsdaten werden solange verarbeitet, wie es für die oben
              dargestellten Zwecke erforderlich ist.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>HOSTING</p>
            <p>
              Die Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch unsere Dienstleister im Rahmen einer Verarbeitung in
              unserem Auftrag erbracht. Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert wird, werden alle
              Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet.
              Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser
              Datenschutzerklärung beschriebenen Kontaktmöglichkeit. Unsere Dienstleister sitzen und verwenden Server in Israel. Für Israel hat die
              Europäische Kommission durch Beschluss ein angemessenes Datenschutzniveau festgestellt. Zusätzlich verwenden unsere Dienstleister Server
              in den USA, Südkorea und Taiwan sowie in weiteren Ländern außerhalb der EU und des EWR, für die kein Angemessenheitsbeschluss der
              Europäischen Kommission vorliegt. Unsere Zusammenarbeit mit Ihnen stützt sich auf Standarddatenschutzklauseln der Europäischen
              Kommission.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <div className='w-full'>
              <p className='data-protection-page__content-article__title'>2. DATENVERARBEITUNG ZUR KONTAKTAUFNAHME UND KUNDENKOMMUNIKATION</p>
              <p className='data-protection-page__content-article__title'>2.1 NUTZERKONTO</p>
            </div>
            <p>
              Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, indem Sie sich für die Eröffnung eines
              Nutzerkontos entscheiden, verwenden wir Ihre Daten zum Zwecke der Nutzerkontoeröffnung Die Löschung Ihres Nutzerkontos ist jederzeit
              möglich und kann entweder durch eine Nachricht an die in dieser Datenschutzerklärung beschriebene Kontaktmöglichkeit oder über eine
              dafür vorgesehene Funktion im Nutzerkonto erfolgen. Nach Löschung Ihres Nutzerkontos werden Ihre Daten gelöscht, sofern Sie nicht
              ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber
              hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>2.2 KONTAKTAUFNAHME</p>
            <p>
              Wir erheben personenbezogene Daten, wenn Sie uns diese bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail)
              freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Bearbeitung Ihrer
              Kontaktaufnahme benötigen und sie ohne deren Angabe die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den
              jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten zur Bearbeitung Ihrer Anfragen gemäß Art. 6
              Abs. 1 S. 1 lit. b DSGVO. Nach vollständiger Abwicklung Ihrer Kundenanfrage werden Ihre Daten für die weitere Verarbeitung eingeschränkt
              und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO gelöscht, sofern Sie nicht
              ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber
              hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <div className='w-full'>
              <p className='data-protection-page__content-article__title'>3. COOKIES UND WEITERE TECHNOLOGIEN</p>
              <p className='data-protection-page__content-article__title'>ALLGEMEINE INFORMATIONEN</p>
            </div>
            <p>
              Um den Besuch unserer Webseite attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf
              verschiedenen Seiten Technologien einschließlich sogenannter Cookies. Cookies sind kleine Textdateien, die automatisch auf Ihrem
              Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres
              Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim
              nächsten Besuch wiederzuerkennen (persistente Cookies). Wir verwenden solche Technologien, die für die Nutzung bestimmter Funktionen
              unserer Webseite (z.B. Präferenzeinstellungen der Webseite) zwingend erforderlich sind. Durch diese Technologien werden IP-Adresse,
              Zeitpunkt des Besuchs, Geräte- und Browser-Informationen sowie Informationen zu Ihrer Nutzung unserer Webseite (z.B. Informationen zu
              Ihren Präferenzen) erhoben und verarbeitet. Dies dient im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an
              einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Cookie-Einstellungen für Ihren Browser finden
              Sie unter den folgenden Links: Microsoft Edge™ [
              <a
                href='https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies'
                target='_blank'
                className='link-style'
                rel='noreferrer'
              >
                https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies
              </a>
              ] / Safari™ [
              <a href='https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14' target='_blank' className='link-style' rel='noreferrer'>
                https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14
              </a>
              ] / Chrome™ [
              <a href='https://support.google.com/chrome/answer/95647?hl=de&hlrm=en' target='_blank' className='link-style' rel='noreferrer'>
                https://support.google.com/chrome/answer/95647?hl=de&hlrm=en
              </a>
              ] / Firefox™ [
              <a
                href='https://support.mozilla.org/de/products/firefox/protect-your-privacy/cookies'
                target='_blank'
                className='link-style'
                rel='noreferrer'
              >
                https://support.mozilla.org/de/products/firefox/protect-your-privacy/cookies
              </a>
              ] / Opera™ [
              <a href='https://help.opera.com/de/latest/web-preferences/#cookies' target='_blank' className='link-style' rel='noreferrer'>
                https://help.opera.com/de/latest/web-preferences/#cookies
              </a>
              ]
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>
              4. EINSATZ VON COOKIES UND ANDEREN TECHNOLOGIEN ZU WEBANALYSE UND WERBEZWECKEN
            </p>
            <p>
              Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, verwenden wir auf unserer Webseite die
              nachfolgenden Cookies und andere Technologien von Drittanbietern. Nach Zweckfortfall und Ende des Einsatzes der jeweiligen Technologie
              durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft
              widerrufen. Weitere Informationen zu Ihren Widerrufsmöglichkeiten finden Sie in dem Abschnitt &quot;Cookies und weitere
              Technologien&quot;. Weitere Informationen einschließlich der Grundlage unserer Zusammenarbeit mit den einzelnen Anbietern finden Sie bei
              den einzelnen Technologien. Bei Fragen zu den Anbietern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die
              in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <div className='w-full'>
              <p className='data-protection-page__content-article__title'>5. SOCIAL MEDIA</p>
              <p className='data-protection-page__content-article__title'>5.1 SOCIAL PLUGINS VON INSTAGRAM (BY META)</p>
            </div>
            <p>
              Auf unserer Webseite werden Social Buttons von sozialen Netzwerken verwendet. Diese sind lediglich als HTML-Links in die Seite
              eingebunden, so dass beim Aufruf unserer Webseite noch keine Verbindung mit den Servern des jeweiligen Anbieters hergestellt wird.
              Klicken Sie auf einen der Buttons, öffnet sich die Webseite des jeweiligen sozialen Netzwerks in einem neuen Fenster Ihres Browsers Dort
              können Sie z.B. den Like- oder Share-Button betätigen.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>5.2 UNSERE ONLINEPRÄSENZ AUF INSTAGRAM (BY META), YOUTUBE, LINKEDIN</p>
            <p>
              Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO gegenüber dem jeweiligen Social Media Betreiber erteilt haben,
              werden bei Besuch unserer Onlinepräsenzen auf den in der oben genannten sozialen Medien Ihre Daten für Marktforschungs- und Werbezwecke
              automatisch erhoben und gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Diese können verwendet
              werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren Interessen entsprechen. Hierzu
              werden im Regelfall Cookies eingesetzt. Die detaillierten Informationen zur Verarbeitung und Nutzung der Daten durch den jeweiligen
              Social Media Betreiber sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
              Privatsphäre, entnehmen Sie bitte den unten verlinkten Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich dennoch Hilfe
              benötigen, können Sie sich an uns wenden. Instagram (by Meta) [
              <a href='http://help.instagram.com/519522125107875' className='link-style' target='_blank' rel='noreferrer'>
                http://help.instagram.com/519522125107875
              </a>
              ] ist ein Angebot der Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Meta Platforms Ireland“) Die durch Meta
              Platforms Ireland automatisch erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf Instagram werden in der Regel an
              einen Server der Meta Platforms, Inc., 1 Hacker Way, Menlo Park, California 94025, USA übertragen und dort gespeichert. Für die USA
              liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf
              Standarddatenschutzklauseln der Europäischen Kommission. Die Datenverarbeitung im Rahmen des Besuchs einer Instagram (by Meta) Fanpage
              erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26 DSGVO. Weitere Informationen (Informationen
              zu Insights-Daten) finden Sie hier [
              <a
                href='https://www.facebook.com/legal/terms/information_about_page_insights_data'
                className='link-style'
                target='_blank'
                rel='noreferrer'
              >
                https://www.facebook.com/legal/terms/information_about_page_insights_data
              </a>
              ].
            </p>
            <p>
              YouTube [
              <a href='https://policies.google.com/privacy?hl=de' className='link-style' target='_blank' rel='noreferrer'>
                https://policies.google.com/privacy?hl=de
              </a>
              ] ist ein Angebot der Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland („Google“). Die von Google automatisch
              erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf YouTube werden in der Regel an einen Server der Google LLC, 1600
              Amphitheatre Parkway Mountain View, CA 94043, USA übertragen und dort gespeichert. Für die USA liegt kein Angemessenheitsbeschluss der
              Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf Standarddatenschutzklauseln der Europäischen Kommission.
            </p>
            <p>
              LinkedIn [
              <a href='https://www.linkedin.com/legal/privacy-policy' className='link-style' target='_blank' rel='noreferrer'>
                https://www.linkedin.com/legal/privacy-policy
              </a>
              ] ist ein Angebot der LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland („LinkedIn“). Die von LinkedIn automatisch
              erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf LinkedIn werden in der Regel an einen Server der LinkedIn
              Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA übertragen und dort gespeichert. Für die USA liegt kein
              Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf Standarddatenschutzklauseln
              der Europäischen Kommission.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <div className='w-full'>
              <p className='data-protection-page__content-article__title'>6. KONTAKTMÖGLICHKEITEN UND IHRE RECHTE</p>
              <p className='data-protection-page__content-article__title'>6.1 IHRE RECHTE</p>
            </div>
            <ul className='data-protection-page__content-article__list'>
              <span>Als Betroffener haben Sie folgende Rechte:</span>
              <li>
                gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu
                verlangen;
              </li>
              <li>
                gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
                personenbezogenen Daten zu verlangen;
              </li>
              <li>
                gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere
                Verarbeitung;
              </li>
              <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
              <li>zur Erfüllung einer rechtlichen Verpflichtung;</li>
              <li>aus Gründen des öffentlichen Interesses oder;</li>
              <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
              <li>gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit;</li>
              <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li>
              <li>die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;</li>
              <li>
                wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen
                oder;
              </li>
              <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
              <li>
                gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
              </li>
              <li>
                gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
                Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
              </li>
              <span>Widerspruchsrecht</span>
            </ul>
            <p>
              Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben
              erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des
              Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt,
              steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu. Nach Ausübung
              Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können
              zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die
              Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. Dies gilt nicht, wenn die Verarbeitung zu
              Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.
            </p>
          </article>
          <article className='data-protection-page__content-article'>
            <p className='data-protection-page__content-article__title'>6.2 KONTAKTMÖGLICHKEITEN</p>
            <p>
              Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder
              Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte
              direkt an uns über die Kontaktdaten in unserem Impressum. Datenschutzerklärung [
              <a href='https://legal.trustedshops.com/produkte/rechtstexter' className='link-style' target='_blank' rel='noreferrer'>
                https://legal.trustedshops.com/produkte/rechtstexter
              </a>
              ] erstellt mit dem Trusted Shops [
              <a href='https://legal.trustedshops.com' className='link-style' target='_blank' rel='noreferrer'>
                https://legal.trustedshops.com
              </a>
              ] Rechtstexter in Kooperation mit FÖHLISCH Rechtsanwälte [
              <a href='https://foehlisch.com' className='link-style' target='_blank' rel='noreferrer'>
                https://foehlisch.com
              </a>
              ].
            </p>
          </article>
        </div>
      </Container>
    </div>
  );
};

export default DataProtectionPage;
