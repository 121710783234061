import LoggedHeaderButtons from 'components/header/headerButtons/LoggedHeaderButtons';
import UnLoggedHeaderButtons from 'components/header/headerButtons/UnLoggedHeaderButtons';
import { useAuth } from 'hooks/index';
import React from 'react';

const HeaderButtons = () => {
  const { accountInfo } = useAuth();

  return accountInfo ? <LoggedHeaderButtons /> : <UnLoggedHeaderButtons />;
};

export default HeaderButtons;
