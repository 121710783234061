import Button from 'components/button';
import RouteBasePaths from 'constants/RouteBasePaths';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const UnLoggedHeaderButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='flex flex-col justify-center items-center space-y-4 sm:space-y-9 lg:space-y-0 lg:space-x-5 lg:flex-row'>
      <Button variant='secondary' onClick={() => navigate(RouteBasePaths.AuthenticatePage, { state: { from: location } })}>
        Login
      </Button>
      <Button
        variant='tertiary'
        onClick={() => navigate(`${RouteBasePaths.AuthenticatePage}/${RouteBasePaths.RegisterPage}`, { state: { from: location } })}
      >
        registrieren
      </Button>
    </div>
  );
};

export default UnLoggedHeaderButtons;
