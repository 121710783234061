import TheKingDomLogo from 'assets/images/new-video-poster.jpeg';
import { any } from 'prop-types';
import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player/lazy';

export const YOU_TUBE_VIDEO_TYPE = 'youtube';

const VideoPlayer = forwardRef(({ src, srcType, autoPlay = true, muted = 'muted', youTubeLink, playing, ...props }, ref) => {
  return !youTubeLink ? (
    <video ref={ref} {...props} muted={muted} autoPlay={autoPlay} loop='loop' preload='none' playsInline poster={TheKingDomLogo}>
      <source src={src} type={srcType} />
      Your browser does not support HTML video.
    </video>
  ) : (
    <ReactPlayer {...props} url={youTubeLink} loop={true} muted={muted} playing={playing} width='100%' height='100%' />
  );
});

VideoPlayer.displayName = 'VideoPlayer';

VideoPlayer.propTypes = {
  src: any,
  srcType: any,
  autoPlay: any,
  muted: any,
  youTubeLink: any,
  playing: any
};

export default VideoPlayer;
