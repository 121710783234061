import { LocalStorageName } from 'constants/index';
import { useCallback } from 'react';
import { AUTHENTICATE_BASE_URL } from 'services/authenticate/configs';
import axiosInstance from 'services/utils/axiosInstance';

const useRegister = () => {
  return useCallback(async (registerData) => {
    try {
      const response = await axiosInstance.post(`${AUTHENTICATE_BASE_URL}/register`, {
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        email: registerData.email,
        password: registerData.password
      });
      if (response) {
        window.localStorage.setItem(LocalStorageName.USER_ID_NAME, JSON.stringify(response.userId));
      }

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }, []);
};

export default useRegister;
