import Container from 'components/container';
import { BreakPoints } from 'constants/index';
import { useWindowSize } from 'hooks/index';
import React, { useLayoutEffect, useMemo, useRef } from 'react';

const SloganSection = () => {
  const sectionContainerRef = useRef(null);
  const circleSvgContainerRef = useRef(null);
  const line1PathRef = useRef(null);
  const line2PathRef = useRef(null);
  const line3PathRef = useRef(null);
  const { width } = useWindowSize();

  const maxLine2Length = useMemo(() => {
    return width >= BreakPoints.BreakpointLg ? 2100 : 0;
  }, [width]);

  useLayoutEffect(() => {
    const handleSetCircleWidth = () => {
      if (sectionContainerRef?.current && circleSvgContainerRef?.current) {
        const sectionContainerWidth = sectionContainerRef.current.offsetWidth;

        circleSvgContainerRef.current.setAttribute('width', `${sectionContainerWidth}px`);

        circleSvgContainerRef.current.setAttribute('height', `${sectionContainerWidth}px`);
      }
    };

    document.addEventListener('resize', handleSetCircleWidth);

    handleSetCircleWidth();

    return () => document.removeEventListener('resize', handleSetCircleWidth);
  }, [sectionContainerRef, circleSvgContainerRef]);

  useLayoutEffect(() => {
    if (line1PathRef?.current) {
      const line1PathCurrent = line1PathRef.current;

      const line1PathLength = line1PathCurrent?.getTotalLength();

      line1PathCurrent.style.strokeDasharray = line1PathLength + ' ' + line1PathLength;

      line1PathCurrent.style.strokeDashoffset = line1PathLength;

      const linePath1Top = line1PathCurrent.getBoundingClientRect().top;
      const linePath1ScrollPercent = linePath1Top / document.documentElement.scrollHeight - 0.12;

      window.addEventListener('scroll', () => {
        // INFO: what % down is it?
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const multipleNumber = 12;
        if (scrollPercentage >= linePath1ScrollPercent) {
          const drawLengthPath1 = line1PathLength * (scrollPercentage - linePath1ScrollPercent);
          const calculateLine1Offset = line1PathLength - drawLengthPath1 * multipleNumber;
          if (calculateLine1Offset > 0) {
            line1PathCurrent.style.strokeDashoffset = calculateLine1Offset;
          } else {
            line1PathCurrent.style.strokeDashoffset = 0;
          }
        } else {
          line1PathCurrent.style.strokeDashoffset = line1PathLength;
        }
      });
    }
  }, [line1PathRef]);

  useLayoutEffect(() => {
    if (line2PathRef?.current) {
      const line2PathCurrent = line2PathRef.current;
      const line2PathLength = line2PathCurrent?.getTotalLength();

      line2PathCurrent.style.strokeDasharray = line2PathLength + ' ' + line2PathLength;

      line2PathCurrent.style.strokeDashoffset = line2PathLength;
      const line2PathTop = line2PathCurrent.getBoundingClientRect().top;
      const line2PathScrollPercent = line2PathTop / document.documentElement.scrollHeight - 0.12;

      window.addEventListener('scroll', () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        if (scrollPercentage >= line2PathScrollPercent) {
          const drawLengthPath2 = line2PathLength * (scrollPercentage - line2PathScrollPercent);
          let calculateLine2Offset = line2PathLength + drawLengthPath2 * 7;

          if (calculateLine2Offset < maxLine2Length) {
            line2PathCurrent.style.strokeDashoffset = calculateLine2Offset;
          } else {
            line2PathCurrent.style.strokeDashoffset = maxLine2Length;
          }
        } else {
          line2PathCurrent.style.strokeDashoffset = line2PathLength;
        }
      });
    }
  }, [line2PathRef, maxLine2Length]);

  useLayoutEffect(() => {
    if (line3PathRef?.current && line2PathRef?.current) {
      const line2PathCurrent = line2PathRef.current;
      const line3PathCurrent = line3PathRef.current;
      const line3PathLength = line3PathCurrent?.getTotalLength();

      line3PathCurrent.style.strokeDasharray = line3PathLength + ' ' + line3PathLength;

      line3PathCurrent.style.strokeDashoffset = line3PathLength;

      const line3PathTop = line3PathCurrent.getBoundingClientRect().top;

      const line3PathScrollPercent = line3PathTop / document.documentElement.scrollHeight - 0.12;
      const multiple = 6;

      window.addEventListener('scroll', () => {
        const line2DashOffset = line2PathCurrent.style.strokeDashoffset;

        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);

        if (scrollPercentage >= line3PathScrollPercent && (Number.parseInt(line2DashOffset) >= maxLine2Length || width < BreakPoints.BreakpointLg)) {
          const drawLengthPath3 = line3PathLength * (scrollPercentage - line3PathScrollPercent);
          const calculateLine3Offset = line3PathLength - drawLengthPath3 * multiple;

          if (calculateLine3Offset > 0) {
            line3PathCurrent.style.strokeDashoffset = calculateLine3Offset;
          } else {
            line3PathCurrent.style.strokeDashoffset = 0;
          }
        } else {
          line3PathCurrent.style.strokeDashoffset = line3PathLength;
        }
      });
    }
  }, [line3PathRef, line2PathRef, width]);

  useLayoutEffect(() => {
    if (line2PathRef?.current && line3PathRef?.current) {
      const line2PathCurrent = line2PathRef.current;
      const line3PathCurrent = line3PathRef.current;
      const line2PathLength = line2PathCurrent?.getTotalLength();

      line2PathCurrent.style.strokeDasharray = line2PathLength + ' ' + line2PathLength;

      line2PathCurrent.style.strokeDashoffset = maxLine2Length;

      const line2PathTop = line2PathCurrent.getBoundingClientRect().top;
      const line2PathScrollPercent = line2PathTop / document.documentElement.scrollHeight - 0.12;

      window.addEventListener('scroll', () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const line3PathOffset = line3PathCurrent.style.strokeDashoffset;

        const drawLengthPath2 = line2PathLength * (scrollPercentage - line2PathScrollPercent);
        let calculateLine2Offset = maxLine2Length + drawLengthPath2;
        if (scrollPercentage >= line2PathScrollPercent && Number.parseInt(line3PathOffset) === 0) {
          line2PathCurrent.style.strokeDashoffset = calculateLine2Offset;
        }
      });
    }
  }, [line2PathRef, line3PathRef, maxLine2Length]);

  return (
    <>
      <section className='w-full relative py-10 xl:h-screen'>
        <Container className='h-full flex flex-col justify-center items-center space-y-9 sm:space-y-0'>
          <div ref={sectionContainerRef} className='m-auto w-full max-w-xl relative'>
            <div className='w-full relative pb-100'>
              <div className='line-container line-container__3 hidden lg:block'>
                <svg
                  width='1920'
                  height='503'
                  viewBox='0 0 1920 503'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  preserveAspectRatio='xMidYMax meet'
                >
                  <path ref={line1PathRef} d='M3 2L408 501' stroke='url(#paint1_linear_12_2)' strokeWidth='6' />
                  <path ref={line2PathRef} d='M1920 500L405 500' stroke='url(#paint0_linear_12_2)' strokeWidth='6' />
                  <defs>
                    <linearGradient id='paint0_linear_12_2' x1='1490.96' y1='494.5' x2='1490.97' y2='505.5' gradientUnits='userSpaceOnUse'>
                      <stop stopColor='#988313' stopOpacity='0' />
                      <stop offset='0.510417' stopColor='#988313' />
                      <stop offset='1' stopColor='#988313' stopOpacity='0' />
                    </linearGradient>
                    <linearGradient id='paint1_linear_12_2' x1='113.424' y1='146.78' x2='121.942' y2='139.821' gradientUnits='userSpaceOnUse'>
                      <stop stopColor='#988313' stopOpacity='0' />
                      <stop offset='0.510417' stopColor='#988313' />
                      <stop offset='1' stopColor='#988313' stopOpacity='0' />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className='line-container line-container__4'>
                <svg
                  width='576'
                  height='576'
                  viewBox='0 0 891 891'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  preserveAspectRatio='xMidYMax meet'
                  className='rotate-[275deg]'
                  ref={circleSvgContainerRef}
                >
                  <circle ref={line3PathRef} cx='445.5' cy='445.5' r='440.5' stroke='url(#paint0_radial_0_5)' strokeOpacity='0.8' strokeWidth='10' />
                  <defs>
                    <radialGradient
                      id='paint0_radial_0_5'
                      cx='0'
                      cy='0'
                      r='1'
                      gradientUnits='userSpaceOnUse'
                      gradientTransform='translate(446 445) rotate(90.0642) scale(446)'
                    >
                      <stop stopColor='#988313' />
                      <stop offset='0.977683' stopColor='#988313' stopOpacity='0' />
                      <stop offset='0.98881' stopColor='#988313' />
                      <stop offset='1' stopColor='#988313' stopOpacity='0' />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className='text-white-85 absolute w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center space-y-4 sm:px-9'>
                <h2 className='!leading-snug drop-shadow-black-85 text-xl sm:text-2xl lg:text-3xl'>&quot;WE ARE MORE THAN A BRAND&quot;</h2>
                <p className='!leading-snug drop-shadow-black-85 sm:text-lg'>We Empower, We Enrich, We Educate</p>
                <p className='!leading-snug drop-shadow-black-85 hidden sm:block sm:text-lg'>
                  The Kingdom ist nicht nur ein Movement, sondern ein Statement, dass das Leben nicht unendlich ist, aber wir gemeinsam etwas
                  unendliches kreieren können. Für uns, für unsere Familien und deren Familien.
                </p>
              </div>
            </div>
          </div>
          <p className='!leading-snug text-white-85 text-center drop-shadow-black-85 block sm:hidden sm:text-lg'>
            The Kingdom ist nicht nur ein Movement, sondern ein Statement, dass das Leben nicht unendlich ist, aber wir gemeinsam etwas unendliches
            kreieren können. Für uns, für unsere Familien und deren Familien.
          </p>
        </Container>
      </section>
    </>
  );
};

export default SloganSection;
