import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerHeight: 0
};

const layoutSlice = createSlice({
  name: 'layout/mainLayout',
  initialState: initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    }
  }
});

export const { setHeaderHeight } = layoutSlice.actions;

export default layoutSlice.reducer;
