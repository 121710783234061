import HeaderButtons from 'components/header/headerButtons';
import HamburgerButton from 'components/header/hompageHeader/HamburgerButton';
import HeaderNavLink from 'components/header/hompageHeader/HeaderNavLink';
import { bool, number } from 'prop-types';
import React from 'react';

const HomepageHeader = ({ isDisplayHamburgerButton, headerOffsetHeight }) => {
  return (
    <>
      {!isDisplayHamburgerButton ? (
        <>
          <HeaderNavLink />
          <HeaderButtons />
        </>
      ) : (
        <HamburgerButton headerHeight={headerOffsetHeight} />
      )}
    </>
  );
};

HomepageHeader.propTypes = {
  isDisplayHamburgerButton: bool,
  headerOffsetHeight: number
};

export default HomepageHeader;
