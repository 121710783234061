import Logo from 'assets/images/logo-colorful.png';
import Button from 'components/button';
import ModalComp from 'components/modalComp';
import RouteBasePaths from 'constants/RouteBasePaths';
import GoBackButton from 'pages/authenticatePage/authenticate/goBackButton';
import { useCloseAuthenticatePage } from 'pages/authenticatePage/useCloseAuthenticatePage';
import { bool } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmailVerificationPopup = ({ isOpen }) => {
  const navigate = useNavigate();
  const handleClosePopup = useCloseAuthenticatePage();

  return (
    <ModalComp isOpen={isOpen} onClose={handleClosePopup}>
      <div className='w-full font-sans space-y-6 sm:space-y-11'>
        <div className='w-full space-y-3.5 sm:space-y-7'>
          <div className='w-full max-w-[134px] mx-auto'>
            <div className='w-full pb-100 relative'>
              <img src={Logo} alt='The Kingdom' className='w-full h-full absolute inset-0 object-cover' />
            </div>
          </div>
          <div className='w-full max-w-xs mx-auto'>
            <h6 className='uppercase text-center font-medium'>Register successful</h6>
            <p className='w-full text-center'>
              We will review and verify your account as soon as possible. In time we are reviewing you can not login your account that you just
              registered
            </p>
          </div>
        </div>
        <div className='w-full flex justify-center items-center space-y-4 !mt-11 flex-col'>
          <Button
            type='button'
            className='shadow-none w-full hover:bg-transparent hover:!text-primary sm:w-fit'
            onClick={() => navigate(RouteBasePaths.HomePage)}
          >
            Continue
          </Button>
          {/* <GoBackButton label='Back to log in' onClick={() => navigate(RouteBasePaths.AuthenticatePage)} />*/}
        </div>
      </div>
    </ModalComp>
  );
};

EmailVerificationPopup.propTypes = {
  isOpen: bool
};

export default EmailVerificationPopup;
