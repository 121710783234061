import ImVisionVideo from 'assets/videos/IM_VISION_Rotterdam_Official_Recap_Video___IM_academy_IM_mastery.mp4';
import Button from 'components/button';
import Container from 'components/container';
import Title from 'components/title';
import VideoPlayer from 'components/videoPlayer';
import RouteBasePaths from 'constants/RouteBasePaths';
import { any } from 'prop-types';
import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const VideoBanner = forwardRef(({ setButtonRef }, ref) => {
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  useLayoutEffect(() => {
    setButtonRef(buttonRef);
  }, [buttonRef]);

  return (
    <section ref={ref} className='w-full h-screen relative overflow-hidden'>
      <div className='absolute w-full h-full inset-0'>
        <VideoPlayer
          // src='https://drive.google.com/uc?export=view&id=1EBfqhTwXEfVwWD3NcyfyjhgwrEQeBJYf'
          src={ImVisionVideo}
          srcType='video/mp4'
          muted='muted'
          autoPlay
          // youTubeLink='https://youtu.be/6DrraFYtdbo'
          // srcType={YOU_TUBE_VIDEO_TYPE}
          className='w-full h-full object-cover'
          // playing={true}
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='bg-linear-black-white absolute w-full h-full inset-0'>
        <Container className='h-full flex flex-col justify-center items-center'>
          <div className='w-full flex justify-center items-center space-x-8 mb-5'>
            {['Business', 'Mindset', 'Lifestyle'].map((text) => (
              <h6 key={text} className='uppercase text-primary sm:text-3xl text-shadow-black-85'>
                {text}
              </h6>
            ))}
          </div>
          <Title title='Your way to freedom' />
          <Button ref={buttonRef} onClick={() => navigate(RouteBasePaths.BecomeAMember)}>
            Mehr erfahren
          </Button>
        </Container>
      </div>
    </section>
  );
});

VideoBanner.displayName = 'VideoBanner';

VideoBanner.propTypes = {
  setButtonRef: any
};

export default VideoBanner;
